import config from './config';
import { getBasicAuthHeaders } from './utils';

const { clientId, clientSecret } = config;

export default (builder) => ({
  // login
  login: builder.mutation({
    query: (params) => ({
      url: `/oauth/token`,
      method: 'POST',
      headers: getBasicAuthHeaders(clientId, clientSecret),
      params,
    }),
  }),

  // Query the account info of current user
  getAccount: builder.query({
    query: (token) => {
      if (token) {
        return {
          url: `/api/login/user_info`,
          method: 'POST',
          headers: { Authorization: `Bearer ${token}` },
        };
      }
      return {
        url: `/api/login/user_info`,
        method: 'POST',
      };
    },
  }),

  // Query the account info of current user by mutation
  handleGetAccount: builder.mutation({
    query: (token) => ({
      url: `/api/login/user_info`,
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
    }),
  }),

  // Update the account info of Agree
  updateUserAgree: builder.mutation({
    query: (token) => ({
      url: `/api/login/user_agree`,
      method: 'PUT',
      headers: { Authorization: `Bearer ${token}` },
    }),
  }),

  // Query the account info of current user
  jupyterLogin: builder.mutation({
    query: () => ({
      url: `/api/dic/jupyter`,
      method: 'POST',
    }),
  }),

  // Set password for the first login
  accountInitialize: builder.mutation({
    query: ({ token, data }) => ({
      url: `/api/login/init`,
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      data,
    }),
  }),
});
