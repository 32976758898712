import PropTypes from 'prop-types';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

const ListBtn = styled(ListItemButton)(({ theme }) => ({
  height: 40,
  borderTopRightRadius: theme.shape.borderRadiusM,
  borderBottomRightRadius: theme.shape.borderRadiusM,
  '&.Mui-selected': {
    backgroundColor: theme.status.main,
    color: '#fff',
    '& .MuiListItemIcon-root': {
      color: '#fff',
    },
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.status.main,
    color: '#fff',
    '& .MuiListItemIcon-root': {
      color: '#fff',
    },
  },
}));

const NavLink = ({ to, title, icon }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });

  return (
    <ListBtn component={Link} to={to} selected={Boolean(match)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListBtn>
  );
};
NavLink.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.element,
};

export default NavLink;
