import React from 'react';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.css'; // `rehype-katex` does not import the CSS for you
import Code from './components/Code';
import MediumZoom from './components/MediumZoom';
import './github.markdown.css';

const MarkdownViewer = ({ className, source = '' }) => {
  if (!source) {
    return null;
  }

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeRaw, rehypeKatex]}
      // eslint-disable-next-line react/no-children-prop
      children={source}
      className={cx('markdown-body', className)}
      components={{
        code: Code,
        img: MediumZoom,
      }}
    />
  );
};

export default MarkdownViewer;
