import { isEmpty } from 'lodash';
import { makeStyles } from '@mui/styles';
import { FieldArray, useField } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { ReactNode, CSSProperties } from 'react';
import { ArtColumn, BaseTableCSSVariables } from 'ali-react-table';
import { Typography, FormControl, FormLabel, Button, Grid } from '@mui/material';

import Table from '@/components/Table/BaseTable';

export interface TableColumn extends ArtColumn {
  formItemComponent?: ReactNode;
  formItemComponentProps?: object;
}

export interface IFormTableProps<T> {
  name: string;
  columns: TableColumn[];
  required?: boolean;
  label?: ReactNode;
  style?: CSSProperties & BaseTableCSSVariables;
  addTableJson?: T;
}

const formTableStyle = {
  '--bgcolor': '#F0F3F8',
  '--header-bgcolor': '#F0F3F8',
  '--header-color': '#42505C',
  '--color': '#42505C',
  '--border-color': '#D2D6DB',
};

const tableBorder = '1px solid #D2D6DB';
const useStyles = makeStyles({
  formTableCellTd: {
    '&.art-table-wrapper': {
      borderLeft: tableBorder,
      borderRight: tableBorder,
      minWidth: '100% !important',
    },
    '& .art-table-cell ': {
      paddingLeft: '0.5em',
      paddingRight: '0.5em',
      borderLeft: tableBorder,
    },
    '& .art-empty-wrapper ': {
      width: '100%',
    },
    '& .art-table-header-cell': {
      borderLeft: tableBorder,
    },
    '& .art-table-row:first-child, & .art-table-header-cell:first-child': {
      borderLeft: 'none',
    },
  },
  addbutton: {
    border: '1px dashed #1ABAC5',
    background: '#FFFFFF',
    borderRadius: 3,
    width: '100%',
    margin: '0.8em 0',
  },
  formLabelText: {
    fontWeight: 600,
    color: '#42505C',
    margin: '0.8em 0 1em 0',
  },
});

export const TableCellText = ({ name, label, ...rest }: { name: string; label: ReactNode }) => {
  const [field] = useField({ name });
  return (
    <Typography {...rest} title={`${label}`}>
      {field.value}
    </Typography>
  );
};

function FormTable<T extends object>({
  name,
  columns,
  required,
  label = '',
  addTableJson,
  style = {},
}: IFormTableProps<T>) {
  const classes = useStyles();

  return (
    <FieldArray name={name}>
      {({ remove, move, swap, push, insert, unshift, pop, replace, form }) => {
        const dataSource = form.values[name];
        return (
          <>
            <Typography variant="subtitle1">
              <FormControl>
                <FormLabel className={classes.formLabelText} required={required}>
                  {label}
                </FormLabel>
              </FormControl>
            </Typography>
            <Table
              className={classes.formTableCellTd}
              style={{ ...formTableStyle, ...style }}
              columns={[
                ...columns.map(
                  ({
                    code,
                    render,
                    formItemComponent: FormItemComponent = TableCellText,
                    formItemComponentProps = {},
                    ...restColumnProps
                  }) => ({
                    code,
                    dataIndex: 'name',
                    render: (text: unknown, record: unknown, index: number) => {
                      if (typeof render === 'function') {
                        // @ts-ignore
                        return render(text, record, index, { remove, move, swap, push, insert, unshift, pop, replace });
                      }

                      const cProps = formItemComponentProps;
                      // @ts-ignore
                      return <FormItemComponent name={`${name}[${index}].${code}`} label={null} {...cProps} />;
                    },
                    ...restColumnProps,
                  })
                ),
              ]}
              dataSource={dataSource || []}
            />
            {!isEmpty(addTableJson) ? (
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    className={classes.addbutton}
                    startIcon={<AddIcon />}
                    onClick={() => push(addTableJson)}
                  >
                    新增参数
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </>
        );
      }}
    </FieldArray>
  );
}

export default FormTable;
