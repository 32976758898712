const solver = {
  module: 'Solver',
  solverList: 'Solver List',
  groupBy: 'Group By',
  problemType: 'Problem Type',
  solver: 'Solver',
  filters: 'Filters',
  expandAll: 'Expand All',
  collapseAll: 'Collapse All',
  inputType: 'Input Type',
};

export default solver;
