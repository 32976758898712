import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  FormGroupProps,
} from '@mui/material';
import { Field, ErrorMessage, FieldInputProps } from 'formik';
import React, { ReactNode } from 'react';

export interface FormikRadioGroupItem {
  label: string;
  value: string;
  disabled?: boolean;
}

interface FormikRadioGroupProps extends FormGroupProps {
  name: string;
  items: FormikRadioGroupItem[];
  label: string;
  required?: boolean;
}

interface MUIRadioGroupFieldProps extends FieldInputProps<string> {
  errorString?: string;
  children: ReactNode;
  label: string;
  required: boolean;
}

const MUIRadioGroupField: React.FC<MUIRadioGroupFieldProps> = ({
  errorString,
  label,
  children,
  value,
  name,
  onChange,
  onBlur,
  required,
  ...props
}) => (
  <FormControl component="fieldset">
    <FormLabel required={required} component="legend">
      {label}
    </FormLabel>
    <RadioGroup name={name} onChange={onChange} onBlur={onBlur} value={value} {...props}>
      {children}
    </RadioGroup>
    <FormHelperText>{errorString}</FormHelperText>
  </FormControl>
);

const FormikRadio: React.FC<FormikRadioGroupProps> = ({ name, items, label, required = false, ...props }) => (
  <div className="formik-radio">
    <Field
      name={name}
      as={MUIRadioGroupField}
      label={label}
      errorString={<ErrorMessage name={name} />}
      required={required}
      {...props}
    >
      {items.map((item) => (
        <FormControlLabel
          control={<Radio />}
          key={item.value}
          value={item.value}
          label={item.label}
          disabled={item.disabled}
        />
      ))}
    </Field>
  </div>
);

export default FormikRadio;
