export default (builder) => ({
  // Query role list
  listRoles: builder.query({
    query: () => ({
      url: '/api/role/list',
      method: 'GET',
    }),
    providesTags: (result) =>
      result?.data
        ? [...result.data.map(({ id }) => ({ type: 'Role', id })), { type: 'Role', id: 'LIST' }]
        : [{ type: 'Role', id: 'LIST' }],
  }),
});
