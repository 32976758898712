import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { noData } from './common';

// 搜索
const search = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M10.5 19C15.1944 19 19 15.1944 19 10.5C19 5.8056 15.1944 2 10.5 2C5.8056 2 2 5.8056 2 10.5C2 15.1944 5.8056 19 10.5 19Z"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M13.3285 7.17155C12.6046 6.4477 11.6046 6 10.5001 6C9.39553 6 8.39553 6.4477 7.67163 7.17155"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6108 16.6108L20.8535 20.8535"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const searchW = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M10.5 19C15.1944 19 19 15.1944 19 10.5C19 5.8056 15.1944 2 10.5 2C5.8056 2 2 5.8056 2 10.5C2 15.1944 5.8056 19 10.5 19Z"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M13.3285 7.17155C12.6046 6.4477 11.6046 6 10.5001 6C9.39553 6 8.39553 6.4477 7.67163 7.17155"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6108 16.6108L20.8535 20.8535"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const clearBtn = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M22.3909 12.0852L15.959 3.5498L7.06738 10.2501L13.75 18.5001L15.4278 17.3323L22.3909 12.0852Z"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M13.7499 18.4999L11.8307 20.0373L6.5489 20.0369L5.24865 18.3115L2.03271 14.0437L7.2499 10.1123"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path d="M6.60278 20.0359L22.2826 20.0359" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const add = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path d="M12 8V16" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 12H16" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const asterisk = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.12138 6.148L9.33738 9.406L6.36738 8.182L5.80938 9.838L8.94138 10.666L6.78138 13.06L8.14938 14.14L10.0034 11.422L11.8574 14.14L13.2254 13.06L11.0654 10.666L14.1974 9.838L13.6394 8.182L10.6694 9.406L10.8854 6.148H9.12138Z"
      fill="#EF5350"
    />
  </svg>
);

const canlendar = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 5H3C2.44772 5 2 5.44772 2 6V19C2 19.5523 2.44772 20 3 20H21C21.5523 20 22 19.5523 22 19V6C22 5.44772 21.5523 5 21 5Z"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7 3V7" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" />
    <path d="M12.5 11.5H7" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" />
    <path d="M17 15.5H7" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" />
    <path d="M17 3V7" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const clear = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M22.3909 12.0852L15.959 3.5498L7.06738 10.2501L13.75 18.5001L15.4278 17.3323L22.3909 12.0852Z"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M13.7499 18.4999L11.8307 20.0373L6.5489 20.0369L5.24865 18.3115L2.03271 14.0437L7.2499 10.1123"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path d="M6.60278 20.0359L22.2826 20.0359" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const deleted = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
    <path d="M4.5 5V22H19.5V5H4.5Z" stroke="#1ABAC5" strokeWidth="2" strokeLinejoin="round" />
    <path d="M10 10V16.5" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 10V16.5" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2 5H22" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 5L9.6445 2H14.3885L16 5H8Z" stroke="#1ABAC5" strokeWidth="2" strokeLinejoin="round" />
  </svg>
);

const edit = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 22H19C19.5523 22 20 21.5523 20 21V7L15.5 2H5C4.44771 2 4 2.44771 4 3V21C4 21.5523 4.44771 22 5 22Z"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 17.5L15.5 12.5L13.5 10.5L8.5 15.5V17.5H10.5Z"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15 2V7H20" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const expendArrow = (
  <svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.65" clipPath="url(#clip0_1037_6556)">
      <path d="M-6.11959e-07 0L0 14L14 14L14 -6.11959e-07L-6.11959e-07 0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M12.8333 6.99967C12.8333 3.77801 10.2217 1.16634 7 1.16634C3.77833 1.16634 1.16666 3.77801 1.16666 6.99967C1.16666 10.2213 3.77834 12.833 7 12.833C10.2217 12.833 12.8333 10.2213 12.8333 6.99967Z"
        stroke="#4B5560"
        strokeWidth="1"
        strokeLinejoin="round"
      />
      <path
        d="M6.125 4.375L8.75 7L6.125 9.625"
        stroke="#4B5560"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1037_6556">
        <rect width="24" height="24" fill="white" transform="translate(0 14) rotate(-90)" />
      </clipPath>
    </defs>
  </svg>
);

const imgUpload = (
  <svg width="38" height="43" viewBox="0 0 38 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.68758 6.54352L2.76027 7.50148C2.27765 7.59531 1.87726 7.87262 1.61888 8.24683C1.36049 8.62104 1.24411 9.09215 1.32951 9.57362L6.70949 39.9066C6.79602 40.3944 7.07326 40.8012 7.45156 41.0646C7.82986 41.3279 8.30921 41.4478 8.79994 41.3618L33.6794 37.0005C34.1715 36.9142 34.5812 36.6354 34.8385 36.2336C35.0053 35.9731 35.1074 35.6641 35.1348 35.3251L11.4927 35.3251C10.5788 35.3251 9.74926 34.9611 9.14409 34.3701C8.53891 33.779 8.15816 32.9608 8.14198 32.0525L7.68758 6.54352Z"
      stroke="#71C2C8"
      strokeWidth="2"
    />
    <path
      d="M7.92896 32.266L14.1208 24.3577C14.4707 23.911 15.1185 23.8307 15.5679 24.1784C15.6126 24.213 15.6544 24.2512 15.6927 24.2926L19.8048 28.7276C20.1908 29.1439 20.8431 29.1703 21.2619 28.7866C21.3414 28.7138 21.4089 28.6289 21.4618 28.5352L27.1929 18.4009C27.472 17.9073 28.1007 17.7322 28.5971 18.0096C28.7487 18.0944 28.8763 18.2159 28.968 18.3628C34.5042 27.2336 37.3351 31.868 37.4608 32.266C37.5933 32.6855 36.4254 33.796 33.9571 35.5977H9.59928L7.92896 32.266Z"
      fill="#BBE7E1"
    />
    <path
      d="M34.2234 1H10.5068C9.08311 1 7.92896 2.14741 7.92896 3.56281V32.7788C7.92896 34.1942 9.08311 35.3416 10.5068 35.3416H34.2234C35.6471 35.3416 36.8012 34.1942 36.8012 32.7788V3.56281C36.8012 2.14741 35.6471 1 34.2234 1Z"
      stroke="#71C2C8"
      strokeWidth="2"
    />
    <path
      d="M15.9204 16.6334C17.3441 16.6334 18.4983 15.486 18.4983 14.0706C18.4983 12.6552 17.3441 11.5078 15.9204 11.5078C14.4967 11.5078 13.3425 12.6552 13.3425 14.0706C13.3425 15.486 14.4967 16.6334 15.9204 16.6334Z"
      fill="#A7DCD5"
    />
  </svg>
);

const imgDelete = (
  <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 0H0V20H20V0Z" fill="white" fillOpacity="0.01" />
    <path d="M3.75 4.16699V18.3337H16.25V4.16699H3.75Z" stroke="white" strokeWidth="1.6" strokeLinejoin="round" />
    <path d="M8.33325 8.33301V13.7497" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.6667 8.33301V13.7497" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.66675 4.16699H18.3334" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.66675 4.16699L8.03716 1.66699H11.9905L13.3334 4.16699H6.66675Z"
      stroke="white"
      strokeWidth="1.6"
      strokeLinejoin="round"
    />
  </svg>
);

const imgLink = (
  <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 0H0V20H20V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M10.2948 3.98575L4.10763 10.1729C2.64316 11.6374 2.64316 14.0118 4.10763 15.4762C5.57209 16.9407 7.94646 16.9407 9.41092 15.4762L16.7766 8.11056C17.7529 7.13422 17.7529 5.55131 16.7766 4.57501C15.8003 3.59869 14.2174 3.59869 13.2411 4.57501L5.87538 11.9407C5.38726 12.4288 5.38726 13.2203 5.87538 13.7085C6.36355 14.1966 7.15501 14.1966 7.64317 13.7085L13.8303 7.52126"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const fileLink = (
  <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M8.23564 3.1886L3.28591 8.13834C2.11433 9.30991 2.11433 11.2094 3.28591 12.381C4.45748 13.5525 6.35698 13.5525 7.52854 12.381L13.4211 6.48844C14.2021 5.70738 14.2021 4.44104 13.4211 3.66001C12.64 2.87895 11.3737 2.87895 10.5927 3.66001L4.70011 9.55254C4.30961 9.94308 4.30961 10.5762 4.70011 10.9668C5.09064 11.3573 5.72381 11.3573 6.11434 10.9668L11.0641 6.01701"
      stroke="#1ABAC5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const moveDown = (
  <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.99996 10H1.66663L1.66663 3.33333H4.99996"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15 3.33333H18.3333V10H15" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 9.99967V18.333" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14.1667 6.66699L5.83337 6.66699"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66663 14.9997L9.99996 18.333L13.3333 14.9997"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const moveUp = (
  <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 10H18.3334V16.6667H15" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5 16.6667H1.66667L1.66667 10H5"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 10.0003V1.66699" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.83329 13.333H14.1666" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M13.3334 5.00033L10 1.66699L6.66671 5.00033"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const removeLink = (
  <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5 8C15.5 4.13401 12.366 1 8.5 1C4.63401 0.999998 1.5 4.134 1.5 8C1.5 11.866 4.634 15 8.5 15C12.366 15 15.5 11.866 15.5 8ZM6.17091 4.94754L8.50001 7.29075L10.8291 4.94754L11.5384 5.65251L9.20499 8L11.5383 10.3475L10.8291 11.0525L8.50001 8.70925L6.17092 11.0525L5.46168 10.3475L7.79503 8L5.46167 5.65251L6.17091 4.94754Z"
      fill="#4B5560"
      fillOpacity="0.4"
    />
  </svg>
);

const myCode = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 22H19C19.5523 22 20 21.5523 20 21V7L15.5 2H5C4.44771 2 4 2.44771 4 3V21C4 21.5523 4.44771 22 5 22Z"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13.5 12L16 14.5L13.5 17" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.5 12L8 14.5L10.5 17" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 2V7H20" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const fork = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 16.5V7.5" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 4.5H5V7.5H19V4.5Z" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M4 16L7 12.5H16.9872L20 16"
      stroke="#1ABAC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 16.5H2V20.5H6V16.5Z" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 16.5H10V20.5H14V16.5Z" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 16.5H18V20.5H22V16.5Z" stroke="#1ABAC5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const SearchIcon = (props) => <SvgIcon {...props}>{search}</SvgIcon>;
export const SearchWIcon = (props) => <SvgIcon {...props}>{searchW}</SvgIcon>;
export const AddIcon = (props) => <SvgIcon {...props}>{add}</SvgIcon>;
export const AsteriskIcon = (props) => <SvgIcon {...props}>{asterisk}</SvgIcon>;
export const CanlendarIcon = (props) => <SvgIcon {...props}>{canlendar}</SvgIcon>;
export const ClearIcon = (props) => <SvgIcon {...props}>{clear}</SvgIcon>;
export const ClearBtnIcon = (props) => <SvgIcon {...props}>{clearBtn}</SvgIcon>;
export const DeleteIcon = (props) => <SvgIcon {...props}>{deleted}</SvgIcon>;
export const EditIcon = (props) => <SvgIcon {...props}>{edit}</SvgIcon>;
export const ExpendArrowIcon = (props) => <SvgIcon {...props}>{expendArrow}</SvgIcon>;
export const ImgUploadIcon = (props) => <SvgIcon {...props}>{imgUpload}</SvgIcon>;
export const ImgDeleteIcon = (props) => <SvgIcon {...props}>{imgDelete}</SvgIcon>;
export const ImgLinkIcon = (props) => <SvgIcon {...props}>{imgLink}</SvgIcon>;
export const FileLinkIcon = (props) => <SvgIcon {...props}>{fileLink}</SvgIcon>;
export const RemoveLinkIcon = (props) => <SvgIcon {...props}>{removeLink}</SvgIcon>;
export const MoveDownIcon = (props) => <SvgIcon {...props}>{moveDown}</SvgIcon>;
export const MoveUpIcon = (props) => <SvgIcon {...props}>{moveUp}</SvgIcon>;

export const MyCodeIcon = (props) => <SvgIcon {...props}>{myCode}</SvgIcon>;
export const ForkIcon = (props) => <SvgIcon {...props}>{fork}</SvgIcon>;

// 空状态
export const EmptyIcon = (props) => <SvgIcon {...props}>{noData}</SvgIcon>;
