import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FullScreenLoading } from 'components/Loading';
import api from 'store/services/api';
import { authActions } from 'store/authSlice';

export default function RequireAuth({ children }) {
  const dispatch = useDispatch();
  const initialized = useSelector((state) => state.auth.initialized);
  const role = useSelector((state) => state.auth.role);

  const {
    data: account,
    isLoading,
    error,
  } = api.useGetAccountQuery(undefined, {
    skip: initialized, // 通过登录跳转则不需要再次获取用户数据
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (account) {
      dispatch(authActions.setAccount(account));
    }
  }, [account, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(authActions.redirectLogin());
    }
  }, [error, dispatch]);

  if (isLoading || !role) {
    return <FullScreenLoading />;
  }

  // 菜单路由处理
  return children;
}
