import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, IconButton, InputAdornment } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import Formik, { Form, FormItem } from 'components/Form';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSnackbar } from 'notistack';

import api from 'store/services/api';

const validationSchema = Yup.object().shape({
  username: Yup.string().required('请输入您的用户名！'),
  password: Yup.string().required('请输入您的密码！'),
  newPassword: Yup.string().required('请输入您的新密码！'),
});

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [accountInitialize] = api.useAccountInitializeMutation();

  useEffect(() => {
    if (!location.state) {
      navigate('/login', { replace: true });
    }
  }, [location.state, navigate]);

  const handleSubmit = (values, { setSubmitting }) => {
    accountInitialize({ token: location.state.token, data: values })
      .unwrap()
      .then(() => {
        enqueueSnackbar('初始化成功 请登录', { variant: 'success' });
        // localStorage.setItem(`${config.name}_${config.tokenStorageKey}`, location.state.token);
        // dispatch(authActions.setAccount(location.state.account));
        navigate('/login');
      })
      .catch((err) => {
        enqueueSnackbar(`初始化失败：${err.message}`, { variant: 'error' });
        setSubmitting(false);
      });
  };

  return (
    <Box>
      <Formik
        initialValues={{
          ...location?.state?.user,
          newPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormItem disabled name="username" formType="input" label="账户" />
            <FormItem
              name="password"
              formType="input"
              label="密码"
              type={showResetPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowResetPassword((show) => !show)} edge="end">
                      {showResetPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormItem
              autoFocus
              name="newPassword"
              formType="input"
              label="新密码"
              type={showNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowNewPassword((show) => !show)} edge="end">
                      {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                sx={{
                  width: '240px',
                  height: '48px',
                  mt: 4,
                  color: theme.palette.text.white,
                  background: 'linear-gradient(180deg, #23D0C8 -32.29%, #0D9BAB 159.38%)',
                }}
                size="large"
              >
                确定
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
