import React, { createContext, useContext, useState, useMemo } from 'react';

import { defaultLocale, dictionaryList } from '../languages';

// create the language context with default selected language
export const LanguageContext = createContext({
  language: defaultLocale,
  dictionary: dictionaryList[defaultLocale],
});

// it provides the language context to app
export function LanguageProvider(props) {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = useMemo(
    () => ({
      language,
      dictionary,
      setLanguage: (locale) => {
        setLanguage(locale); // it will update the language in state
        setDictionary(dictionaryList[locale]);
      },
    }),
    [language, dictionary]
  );

  return <LanguageContext.Provider value={provider}>{props.children}</LanguageContext.Provider>;
}
