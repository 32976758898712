import { TextFieldProps, FormControl, FormLabel, Grid } from '@mui/material';
import { Field, FieldProps } from 'formik';

function FormText({ name, label, margin, required, size = 'small' }: TextFieldProps) {
  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <FormControl hiddenLabel margin={margin ?? 'normal'} size={size} fullWidth>
          <Grid container>
            <Grid item xs={2.5}>
              <FormLabel style={{ color: '#42505C', fontWeight: 600 }} required={required}>
                {label}
              </FormLabel>
            </Grid>
            <Grid item xs={9.5}>
              {field.value}
            </Grid>
          </Grid>
        </FormControl>
      )}
    </Field>
  );
}

export default FormText;
