import React from 'react';
import { getIn } from 'formik';
import { FormHelperText } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import { ImgUploadIcon, ImgDeleteIcon } from 'components/Icon';
import cx from 'classnames';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    position: 'relative',
    background: 'rgba(235, 236, 243, 0.3)',
    borderRadius: theme.shape.borderRadius,
    border: '1px dashed rgba(75, 85, 96, 0.4)',
  },
  dropzone: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  center: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bottom: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: '14px',
    fontWeight: 400,
    marginTop: theme.spacing(1),
  },
  imgText: {
    color: theme.palette.text.white,
  },
  img: {
    width: '100%',
    height: '100%',
  },
  imgWrapper: {
    width: '100%',
    height: '100%',
    '&:hover $previewWrapper': {
      display: 'block',
    },
  },
  imgCenter: {
    height: '64px',
    width: '64px',
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(235, 254, 255, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewWrapper: {
    display: 'none',
    zIndex: 999,
    background: 'rgba(75, 85, 96, 0.6)',
  },
}));

const FormUploadImg = ({
  field: { name, value },
  form: { touched, errors, setFieldValue },
  disabled,
  width,
  height,
  centerText,
  upload,
}) => {
  const classes = useStyles();
  const error = getIn(touched, name) && getIn(errors, name);
  const { enqueueSnackbar } = useSnackbar();

  const handleDrop = async (files) => {
    if (files) {
      const url = await upload(files[0]);
      if (url) {
        setFieldValue(name, url);
      }
    }
  };

  const handleDelete = () => {
    setFieldValue(name, '');
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, image/jpeg, image/jpg',
    maxFiles: 1,
    maxSize: 8 * 1024 * 1024,
    onDrop: handleDrop,
    onDropRejected: (files) => {
      const { err } = files[0];
      if (err) {
        enqueueSnackbar(err[0].message || '上传数据失败!', { variant: 'error' });
      }
    },
  });
  return (
    <div>
      <div className={classes.container} style={{ width: width || '100%', height: height || 200 }}>
        {value ? (
          <div className={classes.imgWrapper}>
            <img src={value} className={classes.img} alt="img" />
            <div className={cx(classes.previewWrapper, classes.dropzone)}>
              {!disabled && (
                <div className={classes.imgCenter} onClick={handleDelete}>
                  <ImgDeleteIcon />
                </div>
              )}
              {/* <div className={cx(classes.bottom, classes.text, classes.imgText)}>
                <ImgLinkIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                {value.path}
              </div> */}
            </div>
          </div>
        ) : (
          <div {...getRootProps({ className: classes.dropzone })}>
            {!disabled && <input {...getInputProps()} />}
            <div className={classes.center}>
              <ImgUploadIcon sx={{ fontSize: '44px' }} viewBox="0 0 38 43" />
              <span className={classes.text}>{centerText || '添加图片'}</span>
            </div>
            <div className={cx(classes.bottom, classes.text)}>jpg/png/jepg格式，文件小于8M</div>
          </div>
        )}
      </div>
      {error && <FormHelperText error>{error || 'error'}</FormHelperText>}
    </div>
  );
};

export default FormUploadImg;
