import React from 'react';
import { Container, Box, Typography, Link } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

import login from 'assets/img/login.png';
import loginLogo from 'assets/img/title-logo.png';

const ContentStyle = styled('div')(({ theme }) => ({
  height: '100%',
  minWidth: 400,
  flex: 1,
  padding: theme.spacing(9, 6),
  '& .MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1),
  },
}));

export default function AuthLayout() {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', width: '100vw', height: '100vh', flexDirection: 'column' }}>
      <Container
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pt: 10,
        }}
        maxWidth="lg"
      >
        <Box
          sx={{
            flex: 1,
            mx: 3,
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img width="340px" src={login} alt="login" />
        </Box>
        <Box
          sx={{
            width: '450px',
            height: '580px',
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.spacing(3),
            boxShadow: '0px 0px 30px rgba(75, 85, 96, 0.24);',
          }}
        >
          <ContentStyle>
            <Box>
              <img width="220" height={50} src={loginLogo} alt="login-logo" />
            </Box>
            <Typography sx={{ mt: 6, fontSize: '24px', fontWeight: 600 }}>杉数运筹与智能决策教学平台</Typography>
            <Typography sx={{ mt: 0.5, fontSize: '16px' }}>
              Center For Operations Research And Intelligent Decision Making
            </Typography>
            <Outlet />
          </ContentStyle>
        </Box>
      </Container>
      <Typography sx={{ mt: 4, textAlign: 'center' }}>
        Copyright © 2022 杉数科技Cardinal Operations 版权所有{' '}
        <Link target="_blank" href="/privacy-policy_zh.html">
          《隐私政策》
        </Link>
        <Link target="_blank" href="/user-agreement_zh.html">
          《用户协议》
        </Link>
      </Typography>
    </Box>
  );
}
