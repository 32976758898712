import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import api from './services/api';
import commonReducer from './commonSlice';
import authReducer from './authSlice';
import imagesReducer from './imagesSlice';
import filesReducer from './filesSlice';
import useDagReducer from './dag/useDagSlice';

export const store = configureStore({
  reducer: {
    // API reducer
    [api.reducerPath]: api.reducer,

    // Modules reducer
    common: commonReducer,
    auth: authReducer,
    images: imagesReducer,
    files: filesReducer,
    dagCanvas: useDagReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
