import { red, cyan, deepPurple, green } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';

const UserAvatar = ({ role, sx, ...rest }) => {
  const RolesIfo = {
    admin: {
      bcColor: red[50],
      color: red[700],
      text: 'A',
    },
    supervisor: {
      bcColor: deepPurple[50],
      color: deepPurple[500],
      text: 'S',
    },
    trial: {
      bcColor: cyan[50],
      color: cyan[500],
      text: 'T',
    },
    free_trial: {
      bcColor: green[50],
      color: green[500],
      text: 'FT',
    },
  };
  const { bcColor = '', text = '', color = '' } = RolesIfo[role] || RolesIfo.trial;
  return (
    <Avatar sx={{ bgcolor: bcColor, color, width: 36, height: 36, ...sx }} {...rest}>
      {text}
    </Avatar>
  );
};
UserAvatar.propTypes = {
  role: PropTypes.string,
  sx: PropTypes.object,
};

export default UserAvatar;
