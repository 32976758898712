import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

import Header from './Header';
import SideBar from './SideBar';
import Content from './Content';

const siderWidth = 200;
const barHeight = 48;

export default function Layout() {
  const { pathname = '' } = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const matchDownMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const matchUpMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

  useEffect(() => {
    if (matchDownMd) {
      setCollapsed(false);
    }
  }, [matchDownMd]);

  useEffect(() => {
    if (pathname) {
      window.scrollTo({ top: 0 });
    }
  }, [pathname]);

  const changCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const handleClose = () => {
    setCollapsed(false);
  };

  return (
    <Box>
      <SideBar collapsed={collapsed} siderWidth={siderWidth} matchUpMd={matchUpMd} onClose={handleClose} />
      <Header
        barHeight={barHeight}
        collapsed={collapsed}
        siderWidth={siderWidth}
        matchUpMd={matchUpMd}
        changCollapsed={changCollapsed}
      />
      <Content barHeight={barHeight} siderWidth={siderWidth} matchUpMd={matchUpMd} collapsed={collapsed}>
        <Outlet />
      </Content>
    </Box>
  );
}
