const clientId = 'g';
const clientSecret = '123456';
const name = '';
const tokenStorageKey = 'gAccessToken';
const baseURL = '/admin/';

export default {
  clientId,
  clientSecret,
  name,
  tokenStorageKey,
  baseURL,
};
