export default (builder) => ({
  // Upload image
  uploadImage: builder.mutation({
    query: (data) => ({
      url: `/api/file/images`,
      method: 'POST',
      data,
    }),
  }),

  // Upload notebook
  uploadNotebook: builder.mutation({
    query: (data) => ({
      url: `/api/file/pyfile`,
      method: 'POST',
      data,
    }),
  }),

  // Upload notebook
  uploadCsv: builder.mutation({
    query: (data) => ({
      url: `/api/file/csv`,
      method: 'POST',
      data,
    }),
  }),

  // Upload zip
  uploadZip: builder.mutation({
    query: (data) => ({
      url: `/api/file/zip`,
      method: 'POST',
      data,
    }),
  }),

  // Upload slide html
  uploadSlide: builder.mutation({
    query: (data) => ({
      url: `/api/file/htmls`,
      method: 'POST',
      data,
    }),
  }),
});
