/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';
import { getIn, Field, FieldProps } from 'formik';
import { TextFieldProps, TextField, Autocomplete, Chip } from '@mui/material';
import _ from 'lodash';
import { OptionsProps } from './form';

interface FormAutocompleteProps {
  options: OptionsProps[];
  name: string;
  label?: string;
  placeholder?: string;
  textProps?: TextFieldProps;
}

const FormAutocomplete: React.FC<FormAutocompleteProps> = ({
  label = '',
  placeholder = '',
  name,
  options,
  ...rest
}) => {
  const findItemLabel = (val: any) => {
    const lab = options.filter((item) => item.value === val)[0].label;
    return lab;
  };
  return (
    <Field name={name}>
      {({ field: { value }, form: { touched, errors, setFieldValue } }: FieldProps) => {
        const errorText = getIn(touched, name) && getIn(errors, name);
        const isError = Boolean(errorText);

        const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
          setFieldValue(name, newValue);
        };

        return (
          <Autocomplete
            multiple
            value={value}
            onChange={handleChange}
            id="size-small-outlined-multi"
            size="small"
            options={options.map((a) => a.value)}
            getOptionLabel={(v) => findItemLabel(v)}
            {...rest}
            renderInput={(params) => (
              <TextField {...params} label={label} placeholder={placeholder} helperText={errorText} error={isError} />
            )}
          />
        );
      }}
    </Field>
  );
};

export default FormAutocomplete;
