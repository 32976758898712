import { getIn, Field, FieldProps } from 'formik';
import {
  FormControl,
  InputLabel,
  FormHelperText,
  SelectChangeEvent,
  Select,
  MenuItem,
  Chip,
  Box,
  SelectProps,
} from '@mui/material';
import { OptionsProps } from './form';
import { isString } from 'lodash';

interface FormSelectProps extends SelectProps {
  options?: OptionsProps[];
}

function FormmMultipleSelect({ name, label, options, margin, size = 'small', required, ...rest }: FormSelectProps) {
  return (
    <Field name={name}>
      {isString(name)
        ? ({ field: { value }, form: { touched, errors, setFieldValue } }: FieldProps) => {
            const error = getIn(touched, name) && getIn(errors, name);

            const handleChange = (event: SelectChangeEvent<typeof value>) => {
              setFieldValue(name, event.target.value);
            };
            return (
              <FormControl margin={margin ?? 'normal'} size={size} fullWidth>
                <InputLabel required={required}>{label}</InputLabel>
                <Select
                  {...rest}
                  value={value}
                  label={label}
                  size={size}
                  multiple
                  onChange={handleChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((val: unknown) => {
                        const lab = Array.isArray(options) && options.filter((item) => item.value === val)[0].label;
                        return <Chip key={val as string} size={size} label={lab} />;
                      })}
                    </Box>
                  )}
                >
                  {Array.isArray(options) &&
                    options.map(({ value: val, label: lab }) => (
                      <MenuItem key={val} value={val}>
                        {lab}
                      </MenuItem>
                    ))}
                </Select>
                {error && <FormHelperText>{error}</FormHelperText>}
              </FormControl>
            );
          }
        : null}
    </Field>
  );
}

export default FormmMultipleSelect;
