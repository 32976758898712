import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const filesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.origin?.name.localeCompare(b.origin?.name),
});

const filesSlice = createSlice({
  name: 'files',
  initialState: filesAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {
    fileAdded: filesAdapter.addOne,
    filesLoading(state) {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    filesReceived(state, action) {
      if (state.loading === 'pending') {
        // Or, call them as "mutating" helpers in a case reducer
        filesAdapter.setAll(state, action.payload);
        state.loading = 'idle';
      }
    },
    fileUpdated: filesAdapter.updateOne,
  },
});

const filesActions = filesSlice.actions;

const filesSelectors = filesAdapter.getSelectors((state) => state.files);

export { filesSelectors, filesActions };

export default filesSlice.reducer;
