import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import _ from 'lodash';
import { FormHelperText, Grid, IconButton, Box, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { ImgUploadIcon, FileLinkIcon, RemoveLinkIcon } from 'components/Icon';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Loading } from 'components/Loading';

const usefileStyles = makeStyles((theme) => ({
  container: {
    '&:hover': { background: theme.palette.background.secondary },
    '&:hover $hiddleBtn': {
      visibility: 'visible',
    },
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    width: '88%',
    display: 'flex',
    alignItems: 'center',
  },
  hiddleBtn: {
    visibility: 'hidden',
  },
}));

const Container = styled(Box)(({ theme, disabled }) => ({
  cursor: 'pointer',
  position: 'relative',
  background: 'rgba(235, 236, 243, 0.3)',
  borderRadius: theme.shape.borderRadius,
  border: '2px dashed rgba(75, 85, 96, 0.2)',
  '&:hover': disabled ? {} : { borderColor: theme.status.main },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    position: 'relative',
    background: 'rgba(235, 236, 243, 0.3)',
    borderRadius: theme.shape.borderRadius,
    border: '1px dashed rgba(75, 85, 96, 0.2)',
    '&:hover': { borderColor: theme.status.main },
  },
  dropzone: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  center: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontSize: '14px',
    fontWeight: 400,
    marginTop: theme.spacing(1),
  },
}));

const FileList = ({ index, name, onRemove, disabled, canLink = true, canMove = true, onDownload }) => {
  const classes = usefileStyles();
  return (
    <Draggable draggableId={`drap-${index}`} index={index} isDragDisabled={disabled || !canMove}>
      {(_provided, _snapshot) => (
        <div {..._provided.dragHandleProps} {..._provided.draggableProps} ref={_provided.innerRef}>
          <Box
            className={classes.container}
            disabled={disabled}
            sx={{
              backgroundColor: (theme) => _snapshot.isDragging && theme.palette.background.secondary,
            }}
          >
            {canLink && (
              <IconButton
                sx={{ visibility: _snapshot.isDragging && 'visible !important' }}
                className={classes.hiddleBtn}
                size="small"
                onClick={onDownload}
                disabled={disabled}
              >
                <FileLinkIcon sx={{ fontSize: 16 }} />
              </IconButton>
            )}

            <Typography noWrap sx={{ flex: 1, ml: 1 }}>
              {name}
            </Typography>
            <IconButton
              sx={{ visibility: _snapshot.isDragging && 'visible !important' }}
              className={classes.hiddleBtn}
              size="small"
              onClick={onRemove}
              disabled={disabled}
            >
              <RemoveLinkIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
        </div>
      )}
    </Draggable>
  );
};

const FormUpload = ({
  field: { name, value = [] },
  form: { touched, errors, setFieldValue },
  disabled = false,
  width,
  height,
  centerText,
  centersubText,
  accept = '.ipynb',
  upload,
  maxSize,
  maxLength = 10000,
  handleDownload,
  uploadLoading = false,
  canMove,
  canLink,
  direction = 'vertical',
}) => {
  const classes = useStyles();
  const error = getIn(touched, name) && getIn(errors, name);
  const handleDrop = async (files) => {
    if (files) {
      const fileInfo = await upload(files[0]);
      if (fileInfo && value?.length < maxLength) {
        setFieldValue(name, [...value, fileInfo]);
      }
    }
  };

  const handleRemove = (idx) => {
    const newValue = value.filter((item, index) => index !== idx);
    setFieldValue(name, newValue);
  };

  const handleSortDrop = ({ destination, source }) => {
    if (destination && destination.droppableId === source.droppableId && destination.index !== source.index) {
      const newValue = _.clone(value);
      const sourceItem = newValue.splice(source.index, 1)[0];
      newValue.splice(destination.index, 0, sourceItem);
      setFieldValue(name, newValue);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: handleDrop,
    maxSize,
  });

  const layoutGrid = useMemo(() => {
    if (direction === 'horizontal') {
      return 12;
    }
    return 6;
  }, [direction]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={layoutGrid}>
        <Loading isLoading={uploadLoading}>
          <Container disabled={disabled} style={{ width: width || '100%', height: height || 180 }}>
            <div {...getRootProps({ className: classes.dropzone })}>
              {!disabled && <input {...getInputProps()} />}
              <div className={classes.center}>
                <ImgUploadIcon sx={{ fontSize: '44px' }} viewBox="0 0 38 43" />
                <span className={classes.text}>{centerText || '点击或将文件拖拽到这里上传'}</span>
                <span className={classes.text}>{centersubText || `支持扩展名：${accept}`}</span>
              </div>
            </div>
          </Container>
        </Loading>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </Grid>
      <Grid item xs={12} sm={layoutGrid} sx={{ maxHeight: 800, overflowY: 'auto' }}>
        <DragDropContext onDragEnd={handleSortDrop}>
          <Droppable droppableId={`fileList ${name}`}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {!_.isEmpty(value) &&
                  value.map(({ fileName, url }, index) => (
                    <FileList
                      canMove={canMove}
                      canLink={canLink}
                      disabled={disabled}
                      key={index}
                      index={index}
                      name={fileName}
                      onDownload={() => handleDownload(url, fileName)}
                      onRemove={() => handleRemove(index)}
                    />
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </Grid>
  );
};

FileList.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  canMove: PropTypes.bool,
  canLink: PropTypes.bool,
  onDownload: PropTypes.func,
  onRemove: PropTypes.func,
};

FormUpload.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.array,
  }),
  form: PropTypes.shape({
    touched: PropTypes.object,
    setFieldValue: PropTypes.func,
    errors: PropTypes.object,
  }),
  canMove: PropTypes.bool,
  canLink: PropTypes.bool,
  disabled: PropTypes.bool,
  uploadLoading: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  centerText: PropTypes.string,
  centersubText: PropTypes.string,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  upload: PropTypes.func,
  maxSize: PropTypes.number,
  maxLength: PropTypes.number,
  handleDownload: PropTypes.func,
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
};

export default FormUpload;
