import { createSlice } from '@reduxjs/toolkit';
import { FlowElement, Node } from 'react-flow-renderer';
import { isEmpty, cloneDeep } from 'lodash';
import { genDefaultInputNode } from '@/features/Case/CaseDetail/Flow/constant';

const nodeConfig = {} as Node;
const elements = [] as FlowElement<any>[];

export const dagCanvas = {
  id: 0,
  canvasName: '',
  canvasShortDescription: '',
  createdDatetime: '',
  updatedDatetime: '',
  elements,
  // 点击 Node 详情信息
  nodeConfig,
  // admin 账户的状态 TRUE 编辑，FALSE 常规
  adminEdit: false,
  // 本地缓存输入参数
  cacheInputParams: [] as { id: string; inputParamsArr: string[] }[],
  // 备份 elements
  serviceElements: [] as Node[],
};

export const useDagSlice = createSlice({
  name: 'useDagReducer',
  initialState: { ...dagCanvas },
  reducers: {
    // 展示画板
    showCanvasDetail(state, action) {
      const payload = cloneDeep(action.payload);
      if (isEmpty(payload.elements)) {
        payload.elements = [genDefaultInputNode()];
      }

      return {
        ...state,
        ...payload,
      };
    },
    // 切换详情信息
    toggleConfigPanel(state, action) {
      state.nodeConfig = action.payload;
    },
    // 更新详情信息
    updateConfigPanel(state, action) {
      state.nodeConfig = action.payload;
      if (action.payload?.data && Array.isArray(action.payload?.data?.parameters)) {
        const cacheInputParams = state.cacheInputParams;
        const haveIdx = cacheInputParams.findIndex(({ id }) => id === action.payload.id);
        const newInputParamsArr = {
          id: action.payload.id,
          // @ts-ignore
          inputParamsArr: action.payload.data.parameters.map(({ parameterValue }) => parameterValue),
        };

        if (haveIdx !== -1) {
          cacheInputParams.splice(haveIdx, 1, newInputParamsArr);
        } else {
          cacheInputParams.push(newInputParamsArr);
        }
      }
    },
    // 退出，重置所有信息
    resetCanvasConfig() {
      return { ...dagCanvas };
    },
    // 清空 canvas 画布
    clearCanvasConfig(state) {
      const cloneDagCanvas = cloneDeep(dagCanvas);
      cloneDagCanvas.elements = [genDefaultInputNode()];
      return { ...cloneDagCanvas, serviceElements: state.serviceElements, id: state.id, adminEdit: state.adminEdit };
    },
    // admin 切换权限
    adminChangeIsEdit(state, action) {
      state.adminEdit = action.payload;
    },
    /**
     * @description 编辑模块保存初始版本
     */
    saveServiceElements(state, action) {
      state.serviceElements = action.payload;
    },
  },
});

export const {
  showCanvasDetail,
  toggleConfigPanel,
  updateConfigPanel,
  resetCanvasConfig,
  clearCanvasConfig,
  adminChangeIsEdit,
  saveServiceElements,
} = useDagSlice.actions;

export default useDagSlice.reducer;
