import { Field, FieldProps } from 'formik';
import { TextField, TextFieldProps } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/lab';
import cnLocale from 'date-fns/locale/zh-CN';
import { isString } from 'lodash';

const FormDatePicker = ({ name, label, ...rest }: TextFieldProps) => (
  <Field name={name}>
    {isString(name)
      ? ({ field, form }: FieldProps) => {
          const { setFieldValue, setFieldTouched } = form;
          const { value } = field;
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={cnLocale}>
              <DatePicker
                {...field}
                {...(rest as DatePickerProps)}
                label={label}
                value={value}
                onChange={(newValue) => setFieldValue(name, newValue)}
                inputFormat="yyyy-MM-dd"
                mask="____-__-__"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={() => setFieldTouched(name)}
                    error={!!form.errors[name] && !!form.touched[name]}
                    helperText={form.touched[name] && form.errors[name]}
                    fullWidth
                    margin="normal"
                    size="small"
                    {...rest}
                  />
                )}
              />
            </LocalizationProvider>
          );
        }
      : null}
  </Field>
);

export default FormDatePicker;
