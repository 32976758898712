import common from './common';
import home from './home';
import solver from './solver';
import tool from './tool';
import statistic from './statistic';

const dictionary = {
  common,
  home,
  solver,
  tool,
  statistic,
};

export default dictionary;
