import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Toolbar, Button, IconButton, Typography, Popover, MenuList, MenuItem } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import UserAvatar from 'components/UserAvatar';

import { authActions } from 'store/authSlice';

const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));

const Header = ({ siderWidth, barHeight, collapsed, changCollapsed, matchUpMd }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const anchorRef = useRef(null);
  const userName = useSelector((state) => state.auth.userName);
  const role = useSelector((state) => state.auth.role);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onLogout = () => {
    dispatch(authActions.redirectLogin());
  };

  const left = useMemo(() => {
    if (matchUpMd) {
      if (collapsed) {
        return `${siderWidth}px`;
      }
      return theme.spacing(7);
    }
    return 0;
  }, [matchUpMd, collapsed, theme, siderWidth]);

  return (
    <Box
      open={collapsed}
      siderwidth={siderWidth}
      sx={{
        zIndex: theme.zIndex.drawer - 10,
        backgroundColor: theme.palette.background.default,
        position: 'fixed',
        top: 0,
        right: 0,
        left,
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
    >
      <Toolbar sx={{ height: barHeight, minHeight: `${barHeight}px !important` }}>
        <IconButton onClick={changCollapsed} size="small" color="inherit">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
        <Typography variant="body" component="span">
          {userName}
        </Typography>
        <span ref={anchorRef}>
          <UserAvatar
            sx={{
              cursor: 'pointer',
              bgcolor: theme.status.main,
              color: '#fff',
              width: 36,
              height: 36,
              ml: 2,
              fontSize: 14,
            }}
            onClick={handleOpen}
            role={role}
          />
        </span>

        <Popover
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              mt: 1.5,
              ml: 0.5,
              pb: 0,
              overflow: 'inherit',
              boxShadow: theme.shadows[2],
              width: 150,
            },
          }}
        >
          <ArrowStyle />
          <Box>
            <MenuList dense>
              <MenuItem key="3">
                <Button startIcon={<LogoutIcon />} fullWidth size="small" variant="outlined" onClick={onLogout}>
                  Logout
                </Button>
              </MenuItem>
            </MenuList>
          </Box>
        </Popover>
      </Toolbar>
    </Box>
  );
};

Header.propTypes = {
  barHeight: PropTypes.number,
  siderWidth: PropTypes.number,
  collapsed: PropTypes.bool,
  matchUpMd: PropTypes.bool,
  changCollapsed: PropTypes.func,
};

export default Header;
