import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const sizeObj = {
  small: 24,
  default: 32,
  large: 48,
};

export default function Loading({ size = 'default', isLoading = false, centerText, children, wrapperSx, sx }) {
  return (
    <Box
      sx={{
        position: 'relative',
        ...sx,
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 4,
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress size={sizeObj[size]} sx={{ mb: 1 }} />
            <Box>{centerText || 'Loading...'}</Box>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          transition: 'opacity .3s',
          position: 'relative',
          ...(isLoading && {
            opacity: 0.5,
          }),
          ...wrapperSx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

Loading.propTypes = {
  size: PropTypes.oneOf(['small', 'default', 'large']),
  isLoading: PropTypes.bool,
  centerText: PropTypes.string,
  children: PropTypes.element,
  wrapperSx: PropTypes.object,
  sx: PropTypes.object,
};
