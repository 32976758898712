const tool = {
  module: '工具',
  subtitle1: '请输入任务编号和密码来查看或者取消任务。',
  subtitle2: '留空的话将展示任务队列。',
  form: {
    jobNumber: '任务编号',
    jobPassword: '任务密码',
    viewQueue: '查看任务队列',
    viewResult: '查看结果',
    dequeue: '取消任务',
  },
};

export default tool;
