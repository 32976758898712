import { useState, forwardRef, useImperativeHandle } from 'react';
import Modal from 'components/Modal';
import { Loading } from '@/components/Loading';
import { Box, Checkbox, FormControlLabel, Link, Typography } from '@mui/material';
import api from 'store/services/api';
import { useSnackbar } from 'notistack';

// eslint-disable-next-line react/prop-types
const Agreement = ({ loginCallBack, ...rest }, ref) => {
  const [updateUserAgree, { isLoading }] = api.useUpdateUserAgreeMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [isPrivacyAgree, setIsPrivacyAgree] = useState(false);
  const [isUserAgree, setIsUserAgree] = useState(false);
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState('');
  const [user, setUser] = useState({});

  const handlePrivacyAgreeChange = (e) => {
    setIsPrivacyAgree(e.target.checked);
  };
  const handleUserAgreeChange = (e) => {
    setIsUserAgree(e.target.checked);
  };

  useImperativeHandle(ref, () => ({
    handleOpen,
  }));

  const handleOpen = (_token, _user) => {
    setToken(_token);
    setUser(_user);
    setOpen(true);
  };

  const handleConfirm = () => {
    updateUserAgree(token)
      .unwrap()
      .then(() => {
        if (typeof loginCallBack === 'function') {
          loginCallBack(token, user);
        }
        setOpen(false);
      })
      .catch((error) => {
        enqueueSnackbar(`执行失败${error.message}`, { variant: 'error' });
      });
  };

  return (
    <Modal
      title="温馨提示"
      confirmText="同意并登入"
      cancelText="不同意"
      open={open}
      scroll="body"
      width={300}
      fullWidth={false}
      maxWidth="xs"
      confirmDisable={!isUserAgree || !isPrivacyAgree}
      onClose={() => setOpen(false)}
      onConfirm={handleConfirm}
      keepMounted
      innerSx={{ pb: 0 }}
      {...rest}
    >
      <Loading isLoading={isLoading}>
        <>
          <Typography sx={{ mb: 1 }}>感谢您选择杉数运筹与智能决策教学平台以及对杉数科技一直以来的信任！</Typography>
          <Typography>
            我们根据外部监管要求拟定了如下的《隐私条款》与《用户协议》，特此向您说明，请您认真阅读。
          </Typography>
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <FormControlLabel
              label={
                <>
                  我已阅读并同意
                  <Link target="_blank" href="/privacy-policy_zh.html">
                    《隐私政策》
                  </Link>
                  所有事项
                </>
              }
              control={<Checkbox size="small" checked={isPrivacyAgree} onChange={handlePrivacyAgreeChange} />}
            />
            <FormControlLabel
              label={
                <>
                  我已阅读并同意
                  <Link target="_blank" href="/user-agreement_zh.html">
                    《用户协议》
                  </Link>
                  所有事项
                </>
              }
              control={<Checkbox size="small" checked={isUserAgree} onChange={handleUserAgreeChange} />}
            />
          </Box>
        </>
      </Loading>
    </Modal>
  );
};

export default forwardRef(Agreement);
