import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { Drawer, List, Box } from '@mui/material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PersonIcon from '@mui/icons-material/Person';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import WorkIcon from '@mui/icons-material/Work';
import { useSelector } from 'react-redux';

import logo from 'assets/img/chouti-logo.png';
import NavLink from './NavLink';
import { moduleRoleMap } from 'constant/auth';

const menu = [
  {
    key: 'case',
    title: '案例',
    path: '/case',
    icon: <AppRegistrationIcon />,
    roles: moduleRoleMap.get('case'),
  },
  {
    key: 'user',
    title: '用户',
    path: '/user',
    icon: <PersonIcon />,
    roles: moduleRoleMap.get('user'),
  },
  {
    key: 'integation',
    title: '上传中心',
    path: '/integation',
    icon: <CloudUploadIcon />,
    roles: moduleRoleMap.get('integation'),
  },
  {
    key: 'permissions',
    title: '案例权限',
    path: '/permissions',
    icon: <WorkIcon />,
    roles: moduleRoleMap.get('permissions'),
  },
];

const openedMixin = (theme, siderwidth) => ({
  width: siderwidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(7),
  [theme.breakpoints.down('md')]: {
    width: theme.spacing(7),
  },
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(0),
  },
});

const DrawerStyle = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, siderwidth }) => ({
  '&.MuiDrawer-root .MuiDrawer-paper': {
    backgroundColor: theme.palette.background.secondary,
  },
  width: siderwidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, siderwidth),
    '& .MuiDrawer-paper': openedMixin(theme, siderwidth),
  }),
  ...(!open && {
    ...closedMixin(theme, siderwidth),
    '& .MuiDrawer-paper': closedMixin(theme, siderwidth),
  }),
}));

const SHADOW = '0px 0px 0px 0px #fff, 0px 0px 0px 0px #fff, 0px 1px 8px 0px rgb(0 0 0 /16%), 0px 0px 0px 0px #fff;';

const Container = styled('div')(() => ({
  zIndex: 1200,
  boxShadow: SHADOW,
  position: 'fixed',
  top: 0,
  left: 0,
}));

const Logo = styled('div')(({ theme, open }) => ({
  ...(open
    ? {
        width: '128px',
        height: '128px',
        borderRadius: 20,
      }
    : {
        width: '48px',
        height: '48px',
        borderRadius: 4,
      }),
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Sider = ({ onClose, collapsed, siderWidth, matchUpMd }) => {
  const theme = useTheme();
  const role = useSelector((state) => state.auth.role);

  const renderLogo = (open) => (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '200px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Logo open={open}>
        <img width="100%" src={logo} alt="logo" />
      </Logo>
    </Box>
  );

  const menuDom = useMemo(
    () => (
      <List>
        {menu
          .filter((item) => item?.roles === '*' || item?.roles.includes(role))
          .map(({ key, title, path, icon }) => (
            <NavLink key={key} to={path} title={title} icon={icon} />
          ))}
      </List>
    ),
    [role]
  );

  return (
    <Container>
      {matchUpMd ? (
        <DrawerStyle variant="permanent" open={collapsed} siderwidth={siderWidth}>
          {renderLogo(collapsed)}
          {menuDom}
        </DrawerStyle>
      ) : (
        <Drawer
          anchor="left"
          onClose={onClose}
          open={collapsed}
          variant="temporary"
          PaperProps={{
            sx: {
              width: siderWidth,
              backgroundColor: theme.palette.background.secondary,
            },
          }}
        >
          {renderLogo(true)}
          {menuDom}
        </Drawer>
      )}
    </Container>
  );
};

Sider.propTypes = {
  onClose: PropTypes.func,
  collapsed: PropTypes.bool,
  matchUpMd: PropTypes.bool,
  siderWidth: PropTypes.number,
};

export default Sider;
