const common = {
  home: 'home',
  solvers: 'solvers',
  tools: 'tools',
  statistics: 'statistics',
  footer: {
    terms: 'TERMS OF USE',
    qa: 'QUESTIONS AND COMMENTS',
  },
  form: {
    submit: 'Submit',
    reset: 'Reset',
    cancel: 'Cancel',
    refresh: 'Refresh',
  },
};

export default common;
