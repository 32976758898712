import enUS from './enUS';
import zhCN from './zhCN';

export const localeMap = {
  zh: 'zh-CN',
  'zh-CN': 'zh-CN',
};

export const defaultLocale =
  window?.localStorage?.getItem('locale') || localeMap[window?.navigator?.language] || 'en-US';

export const dictionaryList = {
  'en-US': enUS,
  'zh-CN': zhCN,
};

export const languageOptions = [
  { id: 'en-US', text: 'English' },
  { id: 'zh-CN', text: '中文-简体' },
];
