const home = {
  title: 'COAP（Center for Optimization Algorithm Platform）',
  subtitle: '一个为公众用户提供免费的科学计算及运筹学问题求解的平台。',
  solvers: {
    info: `COAP目前支持COPT、COIN等5+个商用和开源求解器引擎调用，能够支持多用户的并发请求，未来将逐步集成全球各大主流求解器引擎。`,
  },
  tools: {
    info: `查看任务当前状态，进行任务管理，获取求解日志和结果等信息`,
  },
  statistics: {
    info: `平台内不同求解引擎的相关使用情况统计`,
  },
  about: {
    title: '关于',
    desc: `COAP是一个面向公众的优化算法服务平台，全称为Center for Optimization Algorithm Platform。它由上海财经大学主导成立，
    依托背靠斯坦福运筹学的杉数科技、北大国际数学中心、复旦上海数学中心及清华交叉信息学院等多国内外科研单位和企业支持，
    旨在为公众用户提供免费的科学计算及运筹学问题求解的平台。本平台支持的求解器运行所需的分布式高性能服务器和软件，
    由上海财经大学提供，由杉数科技提供技术支持。`,
  },
  showMore: '更多',
  quickStart: '快速开始',
};

export default home;
