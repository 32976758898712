import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // 主题
  theme: 'light',
  // 语种
  locale: 'zhCN',
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    // 切换主题
    changeTheme: (state, action) => {
      state.theme = action.payload;
    },
    // 切换语种
    changeLocale: (state, action) => {
      state.locale = action.payload;
    },
  },
});

export const { changeTheme, changeLocale } = commonSlice.actions;

export default commonSlice.reducer;
