import PropTypes from 'prop-types';
import { Dialog, IconButton, Button, Box, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

const ModalWrapper = styled(Box)(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
}));

const TitleWrapper = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  height: '48px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  borderBottom: '1px solid #E7E7E7',
}));

const ContentWrapper = styled(DialogContent)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const FooterWrapper = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'center',
}));

const Modal = ({
  open = false,
  title,
  onClose = () => {},
  onConfirm = () => {},
  onCancel,
  cancelDisable = false,
  confirmDisable = false,
  children,
  noFooter,
  maxWidth,
  cancelText,
  confirmText,
  text,
  innerSx,
  loading,
  ...rest
}) => {
  const handleCancel = () => {
    if (onCancel && typeof onCancel === 'function') {
      onCancel();
    } else {
      onClose();
    }
  };

  return (
    <Dialog open={open} fullWidth={Boolean(true)} maxWidth={maxWidth || 'sm'} onClose={onClose} {...rest}>
      <ModalWrapper>
        <TitleWrapper>
          <DialogTitle sx={{ fontSize: '16px' }}>{title}</DialogTitle>
          <IconButton onClick={onClose}>
            <ClearIcon />
          </IconButton>
        </TitleWrapper>
        <ContentWrapper sx={innerSx}>{text || children}</ContentWrapper>
        {!noFooter && (
          <FooterWrapper>
            <Button
              onClick={handleCancel}
              size="small"
              sx={{ minWidth: '80px' }}
              variant="outlined"
              disabled={cancelDisable}
            >
              {cancelText || '取消'}
            </Button>
            <LoadingButton
              loading={loading || false}
              size="small"
              sx={{ minWidth: '80px' }}
              onClick={onConfirm}
              variant="contained"
              disabled={confirmDisable}
            >
              {confirmText || '确定'}
            </LoadingButton>
          </FooterWrapper>
        )}
      </ModalWrapper>
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  cancelDisable: PropTypes.bool,
  confirmDisable: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  noFooter: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  innerSx: PropTypes.object,
  loading: PropTypes.bool,
};

export default Modal;
