import axios from 'axios';

import config from './config';
import { authActions } from '../authSlice';

const axiosBaseQuery =
  ({ baseURL } = { baseURL: '' }) =>
  async ({ headers = {}, ...axiosOptions }, { dispatch }) => {
    try {
      const response = await axios({
        baseURL,
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(`${config.name}_${config.tokenStorageKey}`)}`,
          ...headers,
        },
        ...axiosOptions,
      });
      console.log('response', response);
      if (response?.data?.access_token) {
        // login api
        return { data: response?.data };
      }
      if (response?.data?.code !== 0) {
        // Backend service internal error
        return { error: { status: response?.data?.code, message: response?.data?.message } };
      }
      return { data: response?.data?.data };
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
          // Not logged in or expired
          dispatch(authActions.redirectLogin());
        }
        return { error: { status: error.response.status, message: '服务器无响应!', response: error.response.data } };
      }
      if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return { error: { status: 500, message: '服务器无响应!', request: error.request } };
      }
      console.log('Error', error.message);
      return { error };
    }
  };
export default axiosBaseQuery;
