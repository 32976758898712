import { buildUUID } from '@/utils/uuid';

export const bodyBg = '#F6F8FB';
export const primaryColor = '#3056E3';
export const lightBorder = '1px solid #D9D9D9';
export const bodyBoxShadow = '0px 4px 4px rgba(0, 0, 0, 0.15)';

/**
 * flow 样式
 */
export const flowStyle = {
  'flow-root': {
    borderLeft: lightBorder,
    '&.react-flow': {
      backgroundColor: bodyBg,
    },
    '& .react-flow__node': {
      width: '20em',
      borderColor: '#C2C8D5',
      // border-radius: 2px,
      boxShadow:
        ' -1px -1px 4px 0 hsl(0deg 0% 88% / 50%), -2px 2px 4px 0 hsl(0deg 0% 96% / 50%), 2px 3px 8px 2px hsl(0deg 0% 59% / 5%)',
    },
    '& .react-flow__node.selected': {
      borderColor: primaryColor,
      borderWidth: '3px',
      backgroundColor: 'rgba(48,86,227,.05)',
      boxShadow: '0 0 3px 3px rgb(48 86 227 / 15%)',
    },
    '& .react-flow__node:hover': {
      borderColor: primaryColor,
      backgroundColor: '#FFF',
      boxShadow: '0 0 5px 5px rgb(48 86 227 / 15%)',
    },
    '& .react-flow__handle': {
      width: 7,
      height: 7,
      border: '2px solid #A9B5B4',
    },
    '& .react-flow__node-default .react-flow__handle, & .react-flow__node-default .react-flow__handle, & .react-flow__node-input .react-flow__handle, & .react-flow__node-input .react-flow__handle':
      {
        backgroundColor: '#FFFFFF',
      },
    '& .react-flow__handle-bottom': {
      // transform: 'translate(-50%, 20%)',
    },
    '& .react-flow__handle-top': {
      // transform: 'translate(-50%, -20%)',
    },
    ' & .react-flow__edge': {
      cursor: 'pointer',
    },
    '& .react-flow__edge path': {
      strokeWidth: 2,
    },
    '& .react-flow__edge:hover path': {
      strokeWidth: 3,
      stroke: '#757575',
    },
    '& .react-flow__edge.selected .react-flow__edge-path': {
      stroke: '#9277ED',
      strokeWidth: 3,
    },
    // '& .react-flow__controls': {
    //   top: 10,
    //   right: 10,
    //   bottom: 'inherit',
    //   left: 'inherit',
    // },
  },
};

/**
 * @description 新建 Node 的位置
 */
export const NODE_POSITION = {
  x: 100,
  y: 100,
};

/**
 * @description 参数类型选项
 */
export const PARAMETER_TYPE = [
  {
    label: 'int',
    value: 'int',
  },
  {
    label: 'string',
    value: 'string',
  },
  {
    label: 'double',
    value: 'double',
  },
];

/**
 * @returns input Node
 */
export const genDefaultInputNode = (id?: string) => ({
  id: id ?? buildUUID(),
  type: 'input',
  position: {
    x: 200,
    y: 200,
  },
  data: {
    label: '开始',
  },
  draggable: true,
  selectable: true,
});
