import { TextFieldProps, TextField } from '@mui/material';
import { Field, FieldProps, getIn } from 'formik';
import { isEmpty } from 'lodash';

function FormInput({ name, label, ...rest }: TextFieldProps) {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const { touched, errors } = form;
        const errorText = getIn(touched, field.name) && getIn(errors, field.name);
        const isError = Boolean(errorText);

        /**
         * @fieldLabelProps {fieldLabelProps} label is Empty add hiddenLabel
         */
        const fieldLabelProps = isEmpty(label) ? { hiddenLabel: true } : { label };

        return (
          <TextField
            error={isError}
            helperText={errorText}
            fullWidth
            margin="normal"
            size="small"
            {...fieldLabelProps}
            {...field}
            {...rest}
          />
        );
      }}
    </Field>
  );
}

export default FormInput;
