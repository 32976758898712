export function base64encode(str) {
  if (typeof btoa === 'function') {
    return btoa(str);
  }
  throw new Error('Unable to encode to base64');
}

export function getBasicAuthHeaders(client, secret) {
  return {
    Authorization: `Basic ${base64encode(`${client}:${secret}`)}`,
  };
}
