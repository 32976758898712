import { ChangeEvent } from 'react';
import { FormControl, FormHelperText, TextFieldProps } from '@mui/material';
import MarkdownEditor from '@/components/Markdown/MarkdownEditor';
import { isString } from 'lodash';
import { Field, FieldProps, getIn } from 'formik';

function FormMarkdown({ name, label, ...rest }: TextFieldProps) {
  return (
    <Field name={name}>
      {isString(name)
        ? ({ field: { value }, form: { touched, errors, setFieldValue } }: FieldProps) => {
            const error = getIn(touched, name) && getIn(errors, name);

            const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setFieldValue(name, event.target.value);
            };
            return (
              <FormControl fullWidth>
                {/* @ts-ignore */}
                <MarkdownEditor label={label} value={value} onChange={handleChange} {...rest} />
                {error && <FormHelperText error>{error}</FormHelperText>}
              </FormControl>
            );
          }
        : null}
    </Field>
  );
}

export default FormMarkdown;
