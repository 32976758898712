export default (builder) => ({
  // Query case permission list
  listCasePermission: builder.query({
    query: (data) => ({
      url: '/api/bind/query',
      method: 'POST',
      data,
    }),
    providesTags: (result) =>
      result?.content
        ? [...result.content.map(({ id }) => ({ type: 'casePermission', id })), { type: 'casePermission', id: 'LIST' }]
        : [{ type: 'casePermission', id: 'LIST' }],
  }),

  // Get case permission
  getCasePermission: builder.query({
    query: (id) => ({
      url: `/api/bind/${id}`,
      method: 'GET',
    }),
  }),

  // Update case permission
  updateCasePermission: builder.mutation({
    query: ({ id, ...data }) => ({
      url: `/api/bind/${id}`,
      method: 'PUT',
      data,
    }),
    invalidatesTags: (result, error, data) => [{ type: 'casePermission', id: data?.id }],
  }),

  // Query User list
  listPermissionUser: builder.query({
    query: (data) => ({
      url: '/api/bind/user/query',
      method: 'POST',
      data,
    }),
  }),
});
