import { useState, useCallback, useEffect } from 'react';

type EventCallback = (this: Document, event_: any) => any;
type OnChangeEventCallback = (this: Document, event_: any, isOpen: boolean) => any;
type NoopFunction = () => void;
type FunctionMap = [
  'requestFullscreen',
  'exitFullscreen',
  'fullscreenElement',
  'fullscreenEnabled',
  'fullscreenchange',
  'fullscreenerror'
];
type FullscreenApi = {
  isSupported: boolean;
  onChange: OnChangeEventCallback;
  onError: EventCallback;
  toggle: (newElement?: HTMLElement | null) => Promise<unknown> | undefined;
  request: (elementRef?: HTMLElement | null) => Promise<unknown>;
  exit: NoopFunction;
  isFullscreen: boolean;
  element: Element | null;
};
type RequestFullscreenOptions = {
  // string will help to ease type casting
  navigationUI?: 'auto' | 'hide' | 'show';
};
type FullScreenOptions = {
  onChange?: OnChangeEventCallback;
  onError?: EventCallback;
  requestFullscreenOptions?: RequestFullscreenOptions;
};

const getFullscreenControls = () => {
  const functionsMap: FunctionMap[] = [
    [
      'requestFullscreen',
      'exitFullscreen',
      'fullscreenElement',
      'fullscreenEnabled',
      'fullscreenchange',
      'fullscreenerror',
    ],
    // New WebKit
    [
      'webkitRequestFullscreen',
      'webkitExitFullscreen',
      'webkitFullscreenElement',
      'webkitFullscreenEnabled',
      'webkitfullscreenchange',
      'webkitfullscreenerror',
    ],
    // Old WebKit
    [
      'webkitRequestFullScreen',
      'webkitCancelFullScreen',
      'webkitCurrentFullScreenElement',
      'webkitCancelFullScreen',
      'webkitfullscreenchange',
      'webkitfullscreenerror',
    ],
    [
      'mozRequestFullScreen',
      'mozCancelFullScreen',
      'mozFullScreenElement',
      'mozFullScreenEnabled',
      'mozfullscreenchange',
      'mozfullscreenerror',
    ],
    [
      'msRequestFullscreen',
      'msExitFullscreen',
      'msFullscreenElement',
      'msFullscreenEnabled',
      'MSFullscreenChange',
      'MSFullscreenError',
    ],
  ] as any;

  let map: FunctionMap = functionsMap[0];

  functionsMap.forEach((m) => {
    if (m[1] in document) {
      map = m;
    }
  });

  return map;
};

const noop = () => {};

/**
 * useFullscreen
 * A hook that helps make the document fullscreen
 */
function useFullscreen(options: FullScreenOptions = {}): FullscreenApi {
  const { onChange: onChangeArgument = noop, onError: onErrorArgument = noop, requestFullscreenOptions = {} } = options;
  const [REQUESTFULLSCREEN, EXITFULLSCREEN, FULLSCREENELEMENT, FULLSCREENENABLED, FULLSCREENCHANGE, FULLSCREENERROR] =
    getFullscreenControls();
  const [isFullscreen, setIsFullscreen] = useState(!!document[FULLSCREENELEMENT]);
  const [element, setElement] = useState(document[FULLSCREENELEMENT]);

  let isSupported = true;
  if (typeof window === 'undefined' || !document[FULLSCREENENABLED]) {
    isSupported = false;
  }

  const request = useCallback(
    async (elementRef?: HTMLElement | null) => {
      try {
        const finalElement = elementRef || document.documentElement;
        return await finalElement[REQUESTFULLSCREEN](requestFullscreenOptions);
      } catch (error) {
        console.log(error);
        return await Promise.resolve(error);
      }
    },
    [REQUESTFULLSCREEN, requestFullscreenOptions]
  );

  // eslint-disable-next-line consistent-return
  const exit = useCallback(async () => {
    if (element) {
      try {
        return await document[EXITFULLSCREEN]();
      } catch (error) {
        console.warn(error);
        return await Promise.resolve(error);
      }
    }
  }, [element, EXITFULLSCREEN]);

  const toggle = useCallback(
    // eslint-disable-next-line consistent-return
    (newElement?: HTMLElement | null) => {
      if (element) {
        return exit();
      }

      if (newElement) {
        return request(newElement);
      }
    },
    [element, exit, request]
  );

  const documentChangeEventListener = useCallback(
    (event: Event) => {
      const currentFullscreenElement = document[FULLSCREENELEMENT];
      const isOpen = !!currentFullscreenElement;
      if (isOpen) {
        // fullscreen was enabled
        setIsFullscreen(true);
        setElement(currentFullscreenElement);
      } else {
        // fullscreen was disabled
        setIsFullscreen(false);
        setElement(null);
      }
      onChangeArgument?.call(document, event, isOpen);
    },
    [FULLSCREENELEMENT, onChangeArgument]
  );

  const documentErrorEventListener = useCallback(
    (event: Event) => {
      onErrorArgument?.call(document, event);
    },
    [onErrorArgument]
  );

  useEffect(() => {
    document.addEventListener(FULLSCREENCHANGE, documentChangeEventListener);
    return () => {
      document.removeEventListener(FULLSCREENCHANGE, documentChangeEventListener);
    };
  }, [FULLSCREENCHANGE, documentChangeEventListener]);

  useEffect(() => {
    document.addEventListener(FULLSCREENERROR, documentErrorEventListener);
    return () => {
      document.removeEventListener(FULLSCREENERROR, documentErrorEventListener);
    };
  }, [FULLSCREENERROR, documentErrorEventListener]);

  return {
    isSupported,
    isFullscreen,
    element,
    exit,
    request,
    toggle,
    onChange: onChangeArgument,
    onError: onErrorArgument,
  };
}

export { useFullscreen };
