const common = {
  home: '首页',
  solvers: '求解器',
  tools: '工具',
  statistics: '统计',
  footer: {
    terms: '使用须知',
    qa: '问题与评论',
  },
  form: {
    submit: '提交',
    reset: '重置',
    cancel: '取消',
    refresh: '刷新',
  },
};

export default common;
