/**
 * @description: flow api
 */
import { dagCanvas } from '../dag/useDagSlice';

export interface FlowCanvas {
  canvasName: string;
  id: number;
  canvasShortDescription: string;
  createdDatetime: string;
  updatedDatetime: string;
  elements: CanvasNode[];
}

export interface CanvasNode {
  id: string;
  type: string;
  position: CanvasPosition;
  data: CanvasNodeDetail;
  source: string;
  target: string;
}

export interface CanvasNodeDetail {
  label: string;
  desc: string;
  notebook: string;
  parameters: Parameter[];
}

export interface Parameter {
  parameterName: string;
  parameterType: string;
  parameterDesc: string;
  parameterDefaultValue: string;
  parameterValue: string;
}

export interface CanvasPosition {
  x: number;
  y: number;
}

export type CanvasTreeList = typeof dagCanvas[];

export default (builder: Recordable) => ({
  /**
   * @description: 查询画板列表
   */
  getCanvasTree: builder.query({
    query: (id: number) => ({
      url: `/api/canvas/list/${id}`,
      method: 'GET',
    }),
    providesTags: ['dag-canvas'],
  }),
  /**
   * @description: 获取画板详情
   */
  getCanvasDetail: builder.query({
    query: (id: number) => ({
      url: `/api/canvas/${id}`,
      method: 'GET',
    }),
  }),
  /**
   * @description: 创建画板
   */
  createEmptyCanvas: builder.mutation({
    query: (data: FlowCanvas) => ({
      url: '/api/canvas',
      method: 'POST',
      data,
    }),
    invalidatesTags: ['dag-canvas'],
  }),
  /**
   * @description: 清空运行日志
   */
  clearCanvasList: builder.mutation({
    query: (data: { ids: number[] }) => ({
      url: `api/canvas/deletes`,
      method: 'DELETE',
      data,
    }),
    invalidatesTags: ['dag-canvas'],
  }),
  /**
   * @description: 更新画板信息
   */
  updateCanvasInfo: builder.mutation({
    query: (data: FlowCanvas) => ({
      url: `/api/canvas/${data.id}`,
      method: 'PUT',
      data,
    }),
    invalidatesTags: ['dag-canvas'],
  }),
  /**
   * @description: 画板运行日志
   */
  runCanvasLog: builder.query({
    query: (id: number) => ({
      url: `api/canvas/record/list/${id}`,
      method: 'GET',
    }),
    providesTags: ['run-canvas-log'],
  }),
  /**
   * @description: 还原画板
   */
  resetCanvasLog: builder.mutation({
    query: (data: { ids: number[] }) => ({
      url: `api/canvas/record/deletes`,
      method: 'DELETE',
      data,
    }),
    // dag-canvas 重置输入参数 nodeConfig
    invalidatesTags: ['run-canvas-log', 'dag-canvas'],
  }),
  /**
   * @description: 画板全部运行
   */
  runAllCanvasNode: builder.mutation({
    query: (data: FlowCanvas) => ({
      url: `api/canvas/run_all/${data.id}`,
      method: 'POST',
      data,
    }),
    invalidatesTags: ['run-canvas-log'],
  }),
  /**
   * @description: 可选代码映射
   */
  optionalCodeMap: builder.query({
    query: (id: number) => ({
      url: `api/case/code/list/${id}`,
      method: 'GET',
    }),
  }),
});
