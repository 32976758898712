/* eslint-disable no-sequences */
export default (builder) => ({
  // Query CASE list
  listCases: builder.query({
    query: (data) => ({
      url: '/api/case/query',
      method: 'POST',
      data,
    }),
    providesTags: (result) =>
      result?.content
        ? [...result.content.map(({ id }) => ({ type: 'Case', id })), { type: 'Case', id: 'LIST' }]
        : [{ type: 'Case', id: 'LIST' }],
  }),
  // Query case detail
  getCase: builder.query({
    query: (id) => ({
      url: `/api/case/${id}`,
      method: 'GET',
    }),
  }),

  // Add new case
  addCase: builder.mutation({
    query: (data) => ({
      url: '/api/case',
      method: 'POST',
      data,
    }),
    invalidatesTags: [{ type: 'Case', id: 'LIST' }],
  }),

  // Update case
  updateCase: builder.mutation({
    query: ({ id, ...data }) => ({
      url: `/api/case/${id}`,
      method: 'PUT',
      data,
    }),
    invalidatesTags: (result, error, data) => [{ type: 'Case', id: data?.id }],
  }),

  // Update case
  updateCaseDetail: builder.mutation({
    query: ({ id, ...data }) => ({
      url: `/api/case/update_expand/${id}`,
      method: 'PUT',
      data,
    }),
    invalidatesTags: (result, error, data) => [{ type: 'Case', id: data?.id }],
  }),

  // Update case
  updateCaseDetailAll: builder.mutation({
    query: ({ id, ...data }) => ({
      url: `/api/case/update_all/${id}`,
      method: 'PUT',
      data,
    }),
    invalidatesTags: (result, error, data) => [{ type: 'Case', id: data?.id }],
  }),

  // Delete case
  deleteCase: builder.mutation({
    query: (ids) => ({
      url: '/api/case/deletes',
      method: 'DELETE',
      data: { ids },
    }),
    invalidatesTags: [{ type: 'Case', id: 'LIST' }],
  }),

  // Query case tags list
  listCaseTags: builder.query({
    query: () => ({
      url: '/api/tag/list',
      method: 'GET',
    }),
    providesTags: (result) =>
      result?.content
        ? [...result.content.map(({ id }) => ({ type: 'Tags', id })), { type: 'Tags', id: 'LIST' }]
        : [{ type: 'Tags', id: 'LIST' }],
  }),
  // Query case top tags list
  listCaseTopTags: builder.query({
    query: () => ({
      url: '/api/tag/top',
      method: 'GET',
    }),
  }),

  // add tag
  addCaseTag: builder.mutation({
    query: (tagName) => ({
      url: '/api/tag',
      method: 'POST',
      data: { tagName },
    }),
    invalidatesTags: [{ type: 'Tags', id: 'LIST' }],
  }),

  // Query case notebook to html
  getHtmlNotebook: builder.query({
    query: ({ caseId, notebookName }) => ({
      url: `/api/canvas/${caseId}/notebooks/${notebookName}/html`,
      method: 'GET',
    }),
  }),

  // Query fork notebook vrsion state
  getForkPollState: builder.query({
    query: (caseId) => ({
      url: `api/case/${caseId}/is-forking`,
      method: 'GET',
    }),
  }),

  // Query case notebook vrsion list
  listNotebookVersion: builder.query({
    query: (caseId) => ({
      url: `/api/case/${caseId}/forks`,
      method: 'GET',
    }),
    providesTags: () => [{ type: 'Version', id: 'LIST' }],
  }),

  // fork case notebook vrsion
  addNotebookVersion: builder.mutation({
    query: (caseId) => ({
      url: `api/case/${caseId}/forks`,
      method: 'POST',
    }),
    invalidatesTags: [{ type: 'Version', id: 'LIST' }],
  }),
});
