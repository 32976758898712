import { createSlice } from '@reduxjs/toolkit';

import config from './services/config';

const initialState = {
  initialized: false,
  permissions: [],
  userName: '',
  role: '',
  permissionsButtons: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccount: (state, action) => {
      const { username, permissions, role } = action.payload;
      state.userName = username;
      state.permissions = permissions;
      state.role = role;
      state.initialized = true;
    },
    redirectLogin: () => {
      window.localStorage.removeItem(`${config.name}_${config.tokenStorageKey}`);
      window.location.href = '/login';
    },
  },
});

export const authActions = {
  ...authSlice.actions,
};

export default authSlice.reducer;
