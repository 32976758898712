import { useTablePipeline, features, BaseTable, BaseTableProps } from 'ali-react-table';
import { Checkbox, Radio } from '@mui/material';
import { isEmpty } from 'lodash';
import Empty from 'components/Empty';
import { TableRowSelection } from './Table';

interface StyleBaseTableProps extends BaseTableProps {
  rowSelection?: TableRowSelection;
  width?: number;
  height?: number;
}

const EmptyContent = () => <Empty sx={{ minHeight: 0 }} />;

const customCss = {
  '--font-size': '14px',
  '--header-bgcolor': '#fff',
  '--header-color': '#000',
  '--row-height': '48px',
  '--header-row-height': '48px',
  '--cell-padding': '0px 8px',
  '--highlight-bgcolor': '#f8f8f8',
  '--border-color': '#e7e7e7',
  '--header-cell-border-vertical': 'none',
  '--cell-border-vertical': 'none',
};

const SelectTable = ({ dataSource, columns, rowSelection, primaryKey, ...rest }: StyleBaseTableProps) => {
  const { selectedRowKeys, onChange, type = 'checkbox' } = rowSelection as TableRowSelection;
  const changeSelect = () =>
    type === 'checkbox'
      ? features.multiSelect({
          ...rowSelection,
          // @ts-ignore
          value: selectedRowKeys,
          onChange,
        })
      : features.singleSelect({
          ...rowSelection,
          // @ts-ignore
          value: selectedRowKeys,
          onChange,
        });
  const pipeline = useTablePipeline({ components: type === 'checkbox' ? { Checkbox } : { Radio } })
    .input({ dataSource, columns })
    .primaryKey(primaryKey || 'id')
    .snapshot('flat')
    .use(changeSelect());
  return <BaseTable components={{ EmptyContent }} {...pipeline.getProps()} {...rest} />;
};

const Table = ({ style, rowSelection, width, height, ...rest }: StyleBaseTableProps) => {
  const tableStyle = { width, height, minWidth: 600, overflow: 'auto', ...customCss, ...style };
  if (isEmpty(rowSelection)) {
    return <BaseTable components={{ EmptyContent }} style={tableStyle} {...rest} />;
  }
  return <SelectTable style={tableStyle} rowSelection={rowSelection} {...rest} />;
};

export default Table;
