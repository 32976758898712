import { useMemo } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

export default function Content({ barHeight, matchUpMd, siderWidth, collapsed, children }) {
  const theme = useTheme();
  const left = useMemo(() => {
    if (matchUpMd) {
      if (collapsed) {
        return `${siderWidth}px`;
      }
      return theme.spacing(7);
    }
    return 0;
  }, [matchUpMd, collapsed, theme, siderWidth]);

  return (
    <Box
      sx={{
        mt: `${barHeight}px`,
        pl: left,
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
    >
      {children}
    </Box>
  );
}

Content.propTypes = {
  barHeight: PropTypes.number,
  collapsed: PropTypes.bool,
  matchUpMd: PropTypes.bool,
  siderWidth: PropTypes.number,
  children: PropTypes.element,
};
