import { FormItemProps } from './form';
import FormSelect from './FormSelect';
import FormInput from './FormInput';
// import FormTextArea from './FormTextArea';
import FormMultipleSelectChip from './FormMultipleSelectChip';
import FormDatePicker from './FormDatePicker';
import FormMarkdown from './FormMarkdown';
import { Field } from 'formik';
import FormUploadImg from './FormUploadImg';
import FormFile from './FormFile';
import FormikRadio from './FormikRadio';
import FormAutocomplete from './FormAutocomplete';

const FormItem = ({ formType, ...rest }: FormItemProps) => {
  // @ts-ignore
  const { name, label, required } = rest;
  switch (formType) {
    case 'input':
      return <FormInput {...rest} />;
    // case 'text_area':
    //   return <FormTextArea {...rest} />;
    case 'select':
      return <FormSelect {...rest} />;
    case 'autocomplete':
      // @ts-ignore
      return <FormAutocomplete {...rest} />;
    case 'radio':
      // @ts-ignore
      return <FormikRadio name={name} label={label} {...rest} />;
    case 'multiple_select':
      return <FormMultipleSelectChip {...rest} />;
    case 'date_picker':
      return <FormDatePicker {...rest} />;
    case 'img_upload':
      return <Field name={name} label={label} required={required} component={FormUploadImg} {...rest} />;
    case 'file':
      // @ts-ignore
      return <Field name={name} label={label} required={required} component={FormFile} {...rest} />;
    case 'markdown':
      return <FormMarkdown {...rest} />;
    default:
      return null;
  }
};

export default FormItem;
