import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import enLocale from 'date-fns/locale/en-US';
import zhLocale from 'date-fns/locale/zh-CN';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import lightTheme from '../theme/light';
import darkTheme from '../theme/dark';
import { useFullscreen } from '@/hooks';
import { LanguageContext, LanguageProvider } from './Language';
import routes from './routes';

// date-fns locale data
const localeMap = {
  'en-US': enLocale,
  'zh-CN': zhLocale,
};

// Material UI locale data
const MUILocaleMap = {
  'en-US': 'enUS',
  'zh-CN': 'zhCN',
};

// 主题
const themeMap = {
  light: lightTheme,
  dark: darkTheme,
};

function App() {
  const { element } = useFullscreen();
  const { theme } = useSelector((state) => state.common);
  const languageContext = React.useContext(LanguageContext);
  const locale = languageContext.language;

  const app = useRoutes(routes);

  return (
    <ThemeProvider theme={themeMap[theme]}>
      <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[MUILocaleMap[locale] || 'enUS'])}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
          <CssBaseline />
          <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }} maxSnack={3} domRoot={element}>
            {app}
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ThemeProvider>
  );
}

const LanguageApp = () => (
  <LanguageProvider>
    <App />
  </LanguageProvider>
);

export default LanguageApp;
