import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Box } from '@mui/material';
import { EmptyIcon } from 'components/Icon';

const Empty = ({ icon, desc, children, sx }) => (
  <Paper
    elevation={0}
    sx={{
      minHeight: 400,
      p: 3,
      textAlign: 'center',
      ...sx,
    }}
  >
    <Box sx={{ mt: 5, mb: 2 }}>{icon || <EmptyIcon style={{ fontSize: 100 }} />}</Box>
    <Box sx={{ color: '#00000040' }}>{desc || '暂无数据'}</Box>
    <Box>{children}</Box>
  </Paper>
);

export default Empty;

Empty.propTypes = {
  sx: PropTypes.object,
  icon: PropTypes.element,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
