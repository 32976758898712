import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const imagesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.origin?.name.localeCompare(b.origin?.name),
});

const imagesSlice = createSlice({
  name: 'images',
  initialState: imagesAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {
    imageAdded: imagesAdapter.addOne,
    imagesLoading(state) {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    imagesReceived(state, action) {
      if (state.loading === 'pending') {
        imagesAdapter.setAll(state, action.payload);
        state.loading = 'idle';
      }
    },
    imageUpdated: imagesAdapter.updateOne,
  },
});

const imagesActions = imagesSlice.actions;

const imagesSelectors = imagesAdapter.getSelectors((state) => state.images);

export { imagesSelectors, imagesActions };

export default imagesSlice.reducer;
