export default (builder) => ({
  // Query user list
  listUsers: builder.query({
    query: (data) => ({
      url: '/api/user/query',
      method: 'POST',
      data,
    }),
    providesTags: (result) =>
      result?.content
        ? [...result.content.map(({ id }) => ({ type: 'User', id })), { type: 'User', id: 'LIST' }]
        : [{ type: 'User', id: 'LIST' }],
  }),

  // Query user detail
  getUser: builder.query({
    query: (id) => ({
      url: `/api/user/${id}`,
      method: 'GET',
    }),
    invalidatesTags: (result, error, id) => [{ type: 'User', id }],
  }),

  // Update user
  updateUser: builder.mutation({
    query: (data) => ({
      url: `/api/user/${data?.id}`,
      method: 'PUT',
      data,
    }),
    invalidatesTags: (result, error, data) => [{ type: 'User', id: data?.id }],
  }),
  // Upload Add new users
  uploadAddUsers: builder.mutation({
    query: (data) => ({
      url: `/api/user/batch_import`,
      method: 'POST',
      data,
    }),
    invalidatesTags: [{ type: 'User', id: 'LIST' }],
  }),

  // Add new user
  addUser: builder.mutation({
    query: (data) => ({
      url: `/api/user`,
      method: 'POST',
      data,
    }),
    invalidatesTags: [{ type: 'User', id: 'LIST' }],
  }),

  // Delete user
  deleteUser: builder.mutation({
    query: (id) => ({
      url: `/api/user/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: [{ type: 'User', id: 'LIST' }],
  }),

  // Batch delete users
  batchDeleteUser: builder.mutation({
    query: (ids) => ({
      url: `/api/user/deletes`,
      method: 'DELETE',
      data: ids,
    }),
    invalidatesTags: [{ type: 'User', id: 'LIST' }],
  }),

  // Reset user password
  resetUserPassword: builder.mutation({
    query: (id) => ({
      url: `/api/user/reset/${id}`,
      method: 'PUT',
    }),
    invalidatesTags: (result, error, id) => [{ type: 'User', id }],
  }),

  // Switch user status
  lockUser: builder.mutation({
    query: ({ id, status }) => ({
      url: `/api/user/in_active/${id}`,
      method: 'PUT',
      data: { status },
    }),
    invalidatesTags: (result, error, data) => [{ type: 'User', id: data?.id }],
  }),

  // Query Organiztion list
  listOrganiztion: builder.query({
    query: (data) => ({
      url: '/api/organize/list',
      method: 'POST',
      data,
    }),
    providesTags: (data) =>
      data.length
        ? [...data.map(({ id }) => ({ type: 'Organiza', id })), { type: 'Organiza', id: 'LIST' }]
        : [{ type: 'Organiza', id: 'LIST' }],
  }),

  // Add new Organiztion
  addOrganiztion: builder.mutation({
    query: (data) => ({
      url: `/api/organize`,
      method: 'POST',
      data,
    }),
    invalidatesTags: [{ type: 'Organiza', id: 'LIST' }],
  }),

  // Switch organize status
  lockOrganiztion: builder.mutation({
    query: ({ id, status }) => ({
      url: `/api/organize/in_active/${id}`,
      method: 'PUT',
      data: { status },
    }),
    invalidatesTags: (result, error, data) => [{ type: 'Organiza', id: data?.id }],
  }),

  // Batch delete organize
  batchDeleteOrganiza: builder.mutation({
    query: (ids) => ({
      url: `/api/organize/deletes`,
      method: 'DELETE',
      data: ids,
    }),
    invalidatesTags: [{ type: 'Organiza', id: 'LIST' }],
  }),

  // update organize status
  updateOrganiztion: builder.mutation({
    query: ({ id, ...data }) => ({
      url: `/api/organize/${id}`,
      method: 'PUT',
      data,
    }),
    invalidatesTags: (result, error, data) => [{ type: 'Organiza', id: data?.id }],
  }),

  // Query organize detail
  getOrganize: builder.query({
    query: (id) => ({
      url: `/api/organize/${id}`,
      method: 'GET',
    }),
    invalidatesTags: (result, error, id) => [{ type: 'Organiza', id }],
  }),
  // list organize hierarchy
  listOrganizehierarchy: builder.query({
    query: (data) => ({
      url: `/api/organize/hierarchy`,
      method: 'POST',
      data,
    }),
    providesTags: (data) =>
      data.length
        ? [...data.map(({ id }) => ({ type: 'Organiza', id })), { type: 'Organiza', id: 'LIST' }]
        : [{ type: 'Organiza', id: 'LIST' }],
  }),

  // Query Organiztion list_user
  listOrganiztionAllUser: builder.query({
    query: (data) => ({
      url: '/api/organize/list_user',
      method: 'POST',
      data,
    }),
  }),
});
