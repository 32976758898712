import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';
import config from './config';
import auth from './auth';
import user from './user';
import role from './role';
import upload from './upload';
import cases from './case';
import flow from './flow';
import casePermission from './casePermission';

const { baseURL } = config;
const tagTypes = ['User', 'Case', 'CaseTag', 'Role', 'Common', 'casePermission', 'Tags', 'Organiza'];

const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseURL,
  }),
  tagTypes,
  endpoints: (builder) => ({
    ...auth(builder),
    ...user(builder),
    ...role(builder),
    ...upload(builder),
    ...cases(builder),
    ...flow(builder),
    ...casePermission(builder),
  }),
});

export default api;
