import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NoAccessAlert from './NoAccessAlert';

export default function RoleAccessControl({ roles }) {
  const role = useSelector((state) => state.auth.role);
  if (roles === '*' || roles?.includes(role)) {
    return <Outlet />;
  }

  return <NoAccessAlert />;
}
