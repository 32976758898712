import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, InputAdornment, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSnackbar } from 'notistack';

import { FormItem } from 'components/Form';
import AgreementModal from './Agreement';
import api from 'store/services/api';
import config from 'store/services/config';
import { authActions } from 'store/authSlice';

const initialValues = {
  username: '',
  password: '',
};

const LoginSchema = Yup.object().shape({
  username: Yup.string().required('请输入您的用户名！'),
  password: Yup.string().required('请输入您的密码！'),
});

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const AgreementRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  // 登录
  const [login] = api.useLoginMutation();

  const [handleGetUserInfo, { isLoading, error }] = api.useHandleGetAccountMutation();

  const getUserInfo = (token, user) => {
    handleGetUserInfo(token)
      .unwrap()
      .then((data) => {
        const account = data;
        if (account) {
          if (account.firstLogin) {
            navigate('/login/init', { state: { account, user, token } });
          } else if (!account.agree) {
            AgreementRef.current.handleOpen(token, user);
          } else {
            enqueueSnackbar('登录成功', { variant: 'success' });
            localStorage.setItem(`${config.name}_${config.tokenStorageKey}`, token);
            dispatch(authActions.setAccount(account));
            navigate('/');
          }
        }
      });
  };

  useEffect(() => {
    if (error) {
      dispatch(authActions.redirectLogin());
    }
  }, [error, dispatch]);

  const handleSubmit = (values) => {
    login({
      grant_type: 'password',
      ...values,
    })
      .unwrap()
      .then((result) => {
        getUserInfo(result.access_token, values);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar('用户名或者密码错误！', { variant: 'error' });
      });
  };

  return (
    <Box>
      <AgreementModal ref={AgreementRef} loginCallBack={getUserInfo} />
      <Formik initialValues={initialValues} validationSchema={LoginSchema} onSubmit={handleSubmit}>
        {() => (
          <Form>
            <FormItem name="username" formType="input" label="用户名" />
            <FormItem
              name="password"
              formType="input"
              label="密码"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword((show) => !show)} edge="end">
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingButton
                loading={isLoading}
                sx={{
                  width: '240px',
                  height: '48px',
                  mt: 6,
                  color: theme.palette.text.white,
                  background: 'linear-gradient(180deg, #23D0C8 -32.29%, #0D9BAB 159.38%)',
                }}
                size="large"
                type="submit"
              >
                登入
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
