import React, { useState, useMemo } from 'react';
import { Box, Grid, ButtonGroup, Button, IconButton, Stack, TextField } from '@mui/material';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import MarkdownViewer from './MarkdownViewer';

const viewOptions = [
  { value: 'left', icon: <FormatAlignLeftIcon /> },
  { value: 'middle', icon: <FormatAlignJustifyIcon /> },
  { value: 'right', icon: <FormatAlignRightIcon /> },
];

const fullscreenStyle = {
  position: 'fixed',
  pl: 2,
  pr: 2,
  pb: 2,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100vw',
  height: '100vh',
  background: '#fff',
  zIndex: 10001,
  overflowX: 'auto',
};

export default function MarkdownEditor({ label, value, onChange, disabled }) {
  const [view, setView] = useState('middle');
  const [fullscreen, setFullscreen] = useState(false);

  const handleViewChange = (newView) => () => {
    setView(newView);
  };

  const toggleFullscreen = () => {
    setFullscreen(!fullscreen);
  };

  const xs = useMemo(() => {
    if (view === 'left') {
      return [12, 0];
    }
    if (view === 'right') {
      return [0, 12];
    }
    return [6, 6];
  }, [view]);

  return (
    <Box sx={fullscreen ? fullscreenStyle : {}}>
      <Box sx={{ width: '100%', py: 1, position: fullscreen ? 'sticky' : 'relative' }}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>{label}</Box>
          <Stack spacing={2} direction="row">
            <ButtonGroup size="small">
              {viewOptions.map((item) => (
                <Button
                  key={item.value}
                  variant={view === item.value ? 'contained' : 'outlined'}
                  onClick={handleViewChange(item.value)}
                >
                  {item.icon}
                </Button>
              ))}
            </ButtonGroup>
            <IconButton size="small" variant="outlined" onClick={toggleFullscreen}>
              {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Stack>
        </Box>
      </Box>
      <Box>
        <Grid container spacing={2}>
          {xs[0] > 0 && (
            <Grid item xs={xs[0]}>
              <TextField
                fullWidth
                multiline
                disabled={disabled}
                minRows={10}
                variant="outlined"
                margin="none"
                InputLabelProps={{ shrink: true }}
                value={value}
                label="markdown"
                onChange={onChange}
              />
            </Grid>
          )}
          {xs[1] > 0 && (
            <Grid item xs={xs[1]}>
              <MarkdownViewer source={value} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}
