import React from 'react';
import mediumZoom from 'medium-zoom';

import './mediumZoom.css';

function ImageZoom({ src, alt, className, color = '#fff', margin = 48, ...restProps }) {
  const zoomRef = React.useRef(mediumZoom({ background: color, margin }));

  const attachZoom = (image) => {
    zoomRef.current.attach(image);
  };

  if (className === 'medium-zoom_disable') {
    return <img src={src} alt={alt} {...restProps} />;
  }

  return (
    <div style={{ textAlign: 'center', margin: '8px' }}>
      <img src={src} alt={alt} ref={attachZoom} {...restProps} />
    </div>
  );
}

export default ImageZoom;
