import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const NoAccessAlert = () => (
  <Alert severity="error">
    <AlertTitle>403</AlertTitle>
    抱歉，您当前帐户无权限访问此模块
  </Alert>
);

export default NoAccessAlert;
