import React from 'react';
import { Navigate } from 'react-router-dom';

import { loadable } from 'components/Loading';
import { RoleAccessControl } from 'components/Auth';
import RequireAuth from 'features/Auth/RequireAuth';
import { moduleRoleMap } from 'constant/auth';
import Layout from 'components/Layout';
import AuthLayout from 'components/Layout/AuthLayout';
import Login from 'features/Auth/Login';
import AccountInitialize from 'features/Auth/AccountInitialize';

const ErrorPage404 = loadable(() => import('components/ErrorPage/404'));
const CaseList = loadable(() => import('features/Case/CaseList/List'));
const CaseDetail = loadable(() => import('features/Case/CaseDetail/Detail'));
const CaseEdit = loadable(() => import('features/Case/CaseEdit/CaseEdit'));
const Integation = loadable(() => import('features/Integation'));
const Instruction = loadable(() => import('features/Case/CaseDetail/Instruction/Instruction'));
const NoteBook = loadable(() => import('features/Case/CaseDetail/Notebook/NoteBook'));
const Flow = loadable(() => import('features/Case/CaseDetail/Flow/Flow'));
const Organization = loadable(() => import('features/Organization/Organization'));

const CasePermissions = loadable(() => import('features/CasePermissions/CasePermissions'));

const routes = [
  {
    element: (
      <RequireAuth>
        <Layout />
      </RequireAuth>
    ),
    children: [
      { index: true, element: <Navigate to="/case" /> },
      {
        path: 'case',
        element: <RoleAccessControl roles={moduleRoleMap.get('case')} />,
        children: [
          { index: true, element: <CaseList /> },
          {
            path: ':id',
            element: <CaseDetail />,
            children: [
              { index: true, element: <Navigate to="instruction" /> },
              { path: 'instruction', element: <Instruction /> },
              { path: 'notebook', element: <NoteBook /> },
              { path: 'flow', element: <Flow /> },
            ],
          },
          { path: 'edit/new', element: <CaseEdit /> },
          { path: 'edit/:id', element: <CaseEdit /> },
        ],
      },
      {
        path: 'user',
        element: <RoleAccessControl roles={moduleRoleMap.get('user')} />,
        children: [{ index: true, element: <Organization /> }],
      },
      {
        path: 'integation',
        element: <RoleAccessControl roles={moduleRoleMap.get('integation')} />,
        children: [{ index: true, element: <Integation /> }],
      },
      {
        path: 'permissions',
        element: <RoleAccessControl roles={moduleRoleMap.get('permissions')} />,
        children: [{ index: true, element: <CasePermissions /> }],
      },
    ],
  },
  {
    path: 'login',
    element: <AuthLayout />,
    children: [
      { index: true, element: <Login /> },
      { path: 'init', element: <AccountInitialize /> },
    ],
  },
  { path: '*', element: <ErrorPage404 /> },
];

export default routes;
